import React from "react";
import { useMixView } from "./MixViewContext";
import MixViewTableBodyRow from "./MixViewTableBodyRow";

const MixViewTableBody = () => {
    const { constituents } = useMixView();

    return (
        <tbody>
            {constituents.map(constituent => {
                return <MixViewTableBodyRow constituent={constituent} key={constituent.id} />;
            })}
        </tbody>
    );
};

export default MixViewTableBody;
