import React, { useEffect, useRef, useState } from "react";
import { Chart, ChartData, ChartDataset, ChartOptions, ChartTypeRegistry } from "chart.js";
import { isNil, merge } from "lodash";
import "./positioners";

import LoadingIndicator from "../../Components/LoadingIndicator";

interface PTChartProps<T1 extends keyof ChartTypeRegistry, T2> {
    id?: string;
    type: T1;
    data: Array<ChartDataset<T1, T2[]>>;
    labels?: Array<string | string[] | number | number[] | Date | Date[]>;
    height?: number | string;
    width?: number | string;
    options?: ChartOptions<T1>;
    disableAnimationAfterLoad?: boolean;
    canZoom?: boolean;
    persistLegendState?: boolean;
    persistZoomState?: boolean;
}

const defaultTickFontSize = 16;
const defaultLegendFontSize = 16;
const defaultFontFamily = "'Lato', sans-serif";

Chart.defaults.font.family = defaultFontFamily;

const DEFAULT_CHART_OPTIONS: ChartOptions = {
    scales: {
        x: {
            ticks: {
                font: {
                    size: defaultTickFontSize
                }
            }
        },
        y: {
            ticks: {
                font: {
                    size: defaultTickFontSize
                }
            }
        }
    },
    plugins: {
        tooltip: {},
        legend: {
            position: "right",
            labels: {
                font: {
                    size: defaultLegendFontSize
                }
            }
        }
    }
};

const getZoomOptions = (canZoom: boolean): ChartOptions => {
    if (!canZoom) {
        return {};
    }
    return {
        plugins: {
            zoom: {
                limits: {
                    y: { min: 0 }
                },
                zoom: {
                    drag: {
                        enabled: true,
                        modifierKey: "shift"
                    },
                    mode: "xy"
                },
                pan: {
                    enabled: true,
                    mode: "xy"
                }
            }
        }
    };
};

export const PTChart = <T1 extends keyof ChartTypeRegistry, T2>({
    id,
    type,
    data,
    labels,
    height = "auto",
    width = "auto",
    options = {} as ChartOptions<T1>,
    canZoom = false,
    persistLegendState = false,
    persistZoomState = false
}: PTChartProps<T1, T2>) => {
    const [chart, setChart] = useState<Chart<T1, T2[]>>();

    const canvas = useRef<HTMLCanvasElement>(null);

    const chartData: ChartData<T1, T2[]> = { labels: labels, datasets: data };

    const chartOpts: ChartOptions<T1> = merge<ChartOptions<T1>, ChartOptions<T1>, ChartOptions<T1>, ChartOptions<T1>>(
        {} as ChartOptions<T1>,
        DEFAULT_CHART_OPTIONS as ChartOptions<T1>,
        options,
        getZoomOptions(canZoom) as ChartOptions<T1>
    );

    useEffect(() => {
        const ctx = canvas.current?.getContext("2d");

        if (ctx === null || ctx === undefined) {
            return;
        }

        const _chart = new Chart(ctx, {
            type,
            data: chartData,
            options: chartOpts
        });

        setChart(_chart);
    }, []);

    useEffect(() => {
        // the data or options has changed
        // so we need to update the chart
        if (isNil(chart)) {
            return;
        }

        if (persistLegendState) {
            const datasetsState = chart.data.datasets.map((d, i) => {
                const meta = chart.getDatasetMeta(i);
                const isHidden = isNil(meta?.hidden) ? d?.hidden : meta.hidden;
                return { label: d.label, hidden: isHidden ?? false };
            });

            const updatedDatasets = chartData.datasets.map(d => {
                const isHidden = datasetsState.find(s => s.label === d.label)?.hidden ?? false;
                return { ...d, hidden: isHidden };
            });

            chartData.datasets = updatedDatasets;
        }

        if (persistZoomState) {
            const xMin = chart.scales.x.min;
            const xMax = chart.scales.x.max;

            const scales: ChartOptions = {
                scales: {
                    x: {
                        min: xMin,
                        max: xMax
                    }
                }
            };

            merge<ChartOptions<T1>, ChartOptions<T1>>(chartOpts, scales as ChartOptions<T1>);
        }

        chart.data = chartData;
        chart.options = chartOpts;

        chart.update();
    }, [chartData, chartOpts, labels]);

    const resetZoom = () => {
        if (!canZoom) {
            return;
        }

        if (isNil(chart)) {
            return;
        }
        chart.resetZoom();
    };

    return (
        <div className="chart-container" style={{ height: height, width: width }} onDoubleClick={resetZoom}>
            {isNil(chart) && <LoadingIndicator centered={true} />}

            <canvas id={id ?? "chart"} ref={canvas} />
        </div>
    );
};

export default PTChart;
