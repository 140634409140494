import { MixConstituentModel, MixModel } from "types/models";
import {
    AddMixConstituentRequest,
    SaveMixConstituentsRequest,
    SaveMixConstituentsResponse,
    SaveNewMixConstituentsRequest
} from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class MixConstituentService {
    public static async getMixConstituents(mixId: string): Promise<MixConstituentModel[]> {
        const mixConstituents = await API.get<MixConstituentModel[]>(`${URL.MIXES}/${mixId}/constituents`);

        return mixConstituents;
    }

    public static async addMixConstituent(
        mixId: string,
        request: AddMixConstituentRequest
    ): Promise<MixConstituentModel> {
        const nextMixConstituent = await API.post<MixConstituentModel>(`${URL.MIXES}/${mixId}/constituents`, request);

        return nextMixConstituent;
    }

    public static async deleteMixConstituent(mixId: string, constituentId: string): Promise<void> {
        await API.delete<void>(`${URL.MIXES}/${mixId}/constituents/${constituentId}`);
    }

    public static async saveMixConstituent(
        mixId: string,
        request: SaveMixConstituentsRequest
    ): Promise<SaveMixConstituentsResponse> {
        const nextMixConstituents = await API.post<SaveMixConstituentsResponse>(
            `${URL.MIXES}/${mixId}/constituents/update`,
            request
        );

        return nextMixConstituents;
    }

    public static async saveNewMixConstituent(request: SaveNewMixConstituentsRequest): Promise<MixModel> {
        const mix = await API.post<MixModel>(`${URL.MIXES}/constituents/create`, request);

        return mix;
    }
}
