import { Tooltip } from "chart.js";
import { ActiveElement } from "chart.js";
import { Point } from "chart.js";

declare module "chart.js" {
    interface TooltipPositionerMap {
        stackedBarPositioner: TooltipPositionerFunction<ChartType>;
    }
}

Tooltip.positioners.stackedBarPositioner = function (elements: ActiveElement[], eventPosition: Point) {
    return {
        x: eventPosition.x,
        y: eventPosition.y,
        xAlign: "center",
        yAlign: "bottom"
    };
};
