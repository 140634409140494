import React from "react";
import ActivableButton from "Components/Button/ActivableButton";
import { ManageAccountTabType } from "./types";
import { useAuth0 } from "auth/react-auth0-spa";
import { UserRole } from "types/models";

interface ManageAccountTabProps {
    type: ManageAccountTabType;
    isActive: boolean;
    label: string;
    onSelected: (type: ManageAccountTabType) => void;
}

const ManageAccountTab = ({ type, isActive, label, onSelected }: ManageAccountTabProps) => {
    const handleSelected = () => {
        onSelected(type);
    };

    return (
        <div className="manage-account--tab">
            <ActivableButton onClick={handleSelected} isActive={isActive}>
                {label}
            </ActivableButton>
        </div>
    );
};

interface ManageAccountTabsProps {
    selectedTab: ManageAccountTabType;
    onTabSelected: (type: ManageAccountTabType) => void;
}

const ManageAccountTabs = ({ selectedTab, onTabSelected }: ManageAccountTabsProps) => {
    const { ptUser } = useAuth0();

    const handleTabSelected = (type: ManageAccountTabType) => {
        if (type === selectedTab) {
            return;
        }

        onTabSelected(type);
    };

    const canManageUsers = ptUser.role === UserRole.APP_ADMIN || ptUser.role === UserRole.ROOT;

    return (
        <div className="manage-account--tabs">
            <ManageAccountTab
                type={ManageAccountTabType.CHANGE_PASSWORD}
                label="Change Password"
                isActive={selectedTab === ManageAccountTabType.CHANGE_PASSWORD}
                onSelected={handleTabSelected}
            />

            {canManageUsers && (
                <ManageAccountTab
                    type={ManageAccountTabType.MANAGE_USERS}
                    label="Manage Users"
                    isActive={selectedTab === ManageAccountTabType.MANAGE_USERS}
                    onSelected={handleTabSelected}
                />
            )}
        </div>
    );
};

export default ManageAccountTabs;
