import React from "react";
import Button from "Components/Button";
import ERPModal from "Components/Modals/ERPModal";
import FormActions from "Components/FormActions";

import "./ConfirmDialog.scss";

export interface ConfirmationOptions {
    title: string;
    description: string;
    canCancel?: boolean;
}

interface ConfirmModalProps extends ConfirmationOptions {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

const ConfirmModal = ({ open, title, description, canCancel = true, onSubmit, onClose }: ConfirmModalProps) => {
    return (
        <ERPModal
            show={open}
            onClose={onClose}
            title={title}
            backdrop={canCancel ? true : "static"}
            backdropClassName="confirm-modal-backdrop"
            footer={
                <FormActions>
                    {canCancel && (
                        <Button variant="grey" onClick={onClose}>
                            Cancel
                        </Button>
                    )}

                    <Button variant="secondary" onClick={onSubmit}>
                        Confirm
                    </Button>
                </FormActions>
            }
            footerClassName="confirm-modal--footer"
        >
            <div className="confirm-modal">
                <div>{description}</div>
            </div>
        </ERPModal>
    );
};

export default ConfirmModal;
