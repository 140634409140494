import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import { Toast } from "Components/Toast";
import { isNil } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import MixConstituentService from "Services/mix-constituent.service";
import { AssessConstituentModel, SprayMethod } from "types/models";
import { SaveMixConstituentsRequest } from "types/requests";
import { isNilOrEmpty, sleep } from "utils/utils";
import { MixViewStateModel } from "./AssessBody";
import { useAssess } from "./AssessContext";
import { convertToAssessConstituent, getCalculatedConstituent } from "./MixViewUtils";

interface MixViewContextProps {
    mixId: string;
    constituents: AssessConstituentModel[];
    selectedConstituentId: string;
    isComparing: boolean;
    isPrimaryMix: boolean;
    mixRiskScore: number;
    isModified: boolean;
    isSaving: boolean;
    updateMixId: (nextMixId: string, checkIfModified?: boolean) => void;
    updateSelectedConstituentId: (nextSelectedConstituentId: string) => void;
    updateConstituentConcentration: (constituent: AssessConstituentModel, nextConcentration: number) => void;
    updateConstituentApplicationRate: (constituent: AssessConstituentModel, nextApplicationRate: number) => void;
    updateConstituentSprayMethod: (constituent: AssessConstituentModel, nextSprayMethod: SprayMethod) => void;
    updateConstituentIsEnabled: (constituent: AssessConstituentModel, nextIsEnabled: boolean) => void;
    handleRemoveConstituent: (constituent: AssessConstituentModel) => void;
    updateCompareMixId?: (compareMixId: string) => void;
    handleSaveMix: () => void;
    onMixClosed: () => void;
}

interface MixViewContextOptions {
    mixState: MixViewStateModel;
    isComparing: boolean;
    isPrimaryMix: boolean;
    updateMixState: React.Dispatch<React.SetStateAction<MixViewStateModel>>;
    updateCompareMixId?: (nextCompareMixId: string) => void;
    onMixClosed: (mixId: string) => void;
    children: React.ReactNode;
}

export const MixViewContext = React.createContext<MixViewContextProps | null>(null);

export const useMixView = () => useContext(MixViewContext);

const MixViewContextProvider = ({
    mixState,
    isComparing,
    isPrimaryMix,
    updateMixState,
    updateCompareMixId,
    onMixClosed,
    children
}: MixViewContextOptions) => {
    const { mixes, updateMixes } = useAssess();
    const confirm = useConfirmation();

    useEffect(() => {
        const fetchMixConstituents = async () => {
            const nextConstituents = await MixConstituentService.getMixConstituents(mixState.mixId);

            const _constituents: AssessConstituentModel[] = nextConstituents.map(c => convertToAssessConstituent(c));

            updateMixState(currentState => {
                return { ...currentState, constituents: _constituents };
            });

            if (!isNilOrEmpty(_constituents)) {
                updateMixState(currentState => {
                    return { ...currentState, selectedConstituentId: _constituents[0].id };
                });
            }

            updateMixState(currentState => {
                return { ...currentState, shouldLoadConstituents: false };
            });
        };

        if (!isNil(mixState.mixId) && mixState.shouldLoadConstituents) {
            fetchMixConstituents();
        }
    }, [mixState.mixId]);

    const mixRiskScore = useMemo(() => {
        return mixState.constituents.filter(c => c.isEnabled).reduce((i, c) => i + c.appliedRiskUnits, 0);
    }, [mixState.constituents]);

    const updateConstituent = (constituent: AssessConstituentModel) => {
        const nextConstituents = [
            ...mixState.constituents.map(c => {
                if (c.id === constituent.id) {
                    return constituent;
                }

                return c;
            })
        ];

        updateMixState(currentState => {
            return { ...currentState, constituents: nextConstituents, isModified: true };
        });
    };

    const updateSelectedConstituentId = (nextSelectedConstituentId: string) => {
        if (mixState.selectedConstituentId === nextSelectedConstituentId) {
            return;
        }

        updateMixState(currentState => {
            return { ...currentState, selectedConstituentId: nextSelectedConstituentId };
        });
    };

    const updateConstituentConcentration = (constituent: AssessConstituentModel, nextConcentration: number) => {
        const nextConstituent = getCalculatedConstituent(
            constituent,
            constituent.applicationRate,
            nextConcentration,
            constituent.sprayMethod
        );

        updateConstituent(nextConstituent);
    };

    const updateConstituentApplicationRate = (constituent: AssessConstituentModel, nextApplicationRate: number) => {
        const nextConstituent = getCalculatedConstituent(
            constituent,
            nextApplicationRate,
            constituent.concentration,
            constituent.sprayMethod
        );

        updateConstituent(nextConstituent);
    };

    const updateConstituentIsEnabled = (constituent: AssessConstituentModel, isEnabled: boolean) => {
        const nextConstituent = { ...constituent, isEnabled: isEnabled };
        updateConstituent(nextConstituent);
    };

    const updateConstituentSprayMethod = (constituent: AssessConstituentModel, nextSprayMethod: SprayMethod) => {
        const nextConstituent = getCalculatedConstituent(
            constituent,
            constituent.applicationRate,
            constituent.concentration,
            nextSprayMethod
        );
        updateConstituent(nextConstituent);
    };

    const handleRemoveConstituent = async (constituent: AssessConstituentModel) => {
        const result = await confirm({
            title: "Remove constituent",
            description: `Are you sure you want to remove ${constituent.name} from this mix?`
        });

        if (!result.success) {
            return;
        }

        await MixConstituentService.deleteMixConstituent(mixState.mixId, constituent.id);

        Toast.success(`Successfully saved ${constituent.name}`);
        const nextConstituents = [...mixState.constituents.filter(c => c.id !== constituent.id)];
        updateMixState(currentState => {
            return { ...currentState, constituents: nextConstituents };
        });
    };

    const handleSaveMix = async () => {
        try {
            updateMixState(currentState => {
                return { ...currentState, isSaving: true };
            });

            const request: SaveMixConstituentsRequest = {
                constituents: mixState.constituents.map(c => {
                    return {
                        constituentId: c.id,
                        concentration: c.concentration,
                        applicationRate: c.applicationRate,
                        sprayMethod: c.sprayMethod,
                        isEnabled: c.isEnabled
                    };
                })
            };

            const response = await MixConstituentService.saveMixConstituent(mixState.mixId, request);

            const _constituents: AssessConstituentModel[] = response.constituents.map(c =>
                convertToAssessConstituent(c)
            );

            const nextMixes = [
                ...mixes.map(m => {
                    if (m.id === response.mix.id) {
                        return response.mix;
                    }

                    return m;
                })
            ];

            updateMixes(nextMixes);

            await sleep(100);

            updateMixState(currentState => {
                return { ...currentState, constituents: _constituents, isModified: false };
            });
        } finally {
            updateMixState(currentState => {
                return { ...currentState, isSaving: false };
            });
        }
    };

    const handleMixClose = async () => {
        if (mixState.isModified) {
            const result = await confirm({
                title: "Unsaved changes",
                description: `Unsaved changes will be lost. Do you wish to close without saving?`
            });

            if (!result.success) {
                return;
            }
        }

        onMixClosed(mixState.mixId);
    };

    const updateMixId = async (nextMixId: string, checkIfModified = true) => {
        if (checkIfModified && mixState.isModified) {
            const result = await confirm({
                title: "Unsaved changes",
                description: `Unsaved changes will be lost. Do you wish to close without saving?`
            });

            if (!result.success) {
                return;
            }
        }

        updateMixState(currentState => {
            return { ...currentState, mixId: nextMixId, shouldLoadConstituents: true, isModified: false };
        });
    };

    return (
        <MixViewContext.Provider
            value={{
                mixId: mixState.mixId,
                constituents: mixState.constituents,
                selectedConstituentId: mixState.selectedConstituentId,
                isComparing: isComparing,
                isPrimaryMix: isPrimaryMix,
                mixRiskScore: mixRiskScore,
                isModified: mixState.isModified,
                isSaving: mixState.isSaving,
                updateMixId: updateMixId,
                updateSelectedConstituentId: updateSelectedConstituentId,
                updateConstituentConcentration: updateConstituentConcentration,
                updateConstituentApplicationRate: updateConstituentApplicationRate,
                updateConstituentSprayMethod: updateConstituentSprayMethod,
                updateConstituentIsEnabled: updateConstituentIsEnabled,
                handleRemoveConstituent: handleRemoveConstituent,
                updateCompareMixId: updateCompareMixId,
                onMixClosed: handleMixClose,
                handleSaveMix: handleSaveMix
            }}
        >
            {children}
        </MixViewContext.Provider>
    );
};

export default MixViewContextProvider;
