import clsx from "clsx";
import React from "react";

import "./FormActions.scss";

interface FormActionsProps {
    className?: string;
    children: React.ReactNode;
}

const FormActions = ({ className = "", children }: FormActionsProps) => {
    return <div className={clsx("form-actions", className)}>{children}</div>;
};

export default FormActions;
