import React from "react";
import PesticideProjectorLogo from "Assets/images/pesticide-projector-logo.svg";
import { Link } from "react-router-dom";

const HeaderIcon = () => {
    return (
        <Link to="/explore" className="header-icon">
            <img src={PesticideProjectorLogo} id="erp-logo" alt="Pesticide Projector" />
        </Link>
    );
};

export default HeaderIcon;
