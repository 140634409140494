import Button from "Components/Button";
import React from "react";
import { ChartScaleOptions } from "types/models";
import { ChartOptions } from "./ExploreBody";

export interface ChartOptionsPanelProps {
    chartOptions: ChartOptions;
    onChartOptionsChanged: (next: ChartOptions) => void;
}

const ChartOptionsPanel = ({ chartOptions, onChartOptionsChanged }: ChartOptionsPanelProps) => {
    const handleScaleChanged = (next: ChartScaleOptions) => {
        if (next === chartOptions.scale) {
            return;
        }

        onChartOptionsChanged({ ...chartOptions, scale: next });
    };

    const handleShowLabelsChanged = (next: boolean) => {
        if (next === chartOptions.showLabels) {
            return;
        }

        onChartOptionsChanged({ ...chartOptions, showLabels: next });
    };

    return (
        <div className="chart-options-panel">
            <span className="label">Chart options</span>
            <div className="toggle">
                <span>Scale</span>
                <div className="toggle-buttons">
                    <Button
                        className="toggle-button"
                        variant={chartOptions.scale === ChartScaleOptions.LOG ? "primary" : "grey"}
                        onClick={() => handleScaleChanged(ChartScaleOptions.LOG)}
                    >
                        Log
                    </Button>
                    <Button
                        className="toggle-button"
                        variant={chartOptions.scale === ChartScaleOptions.LINEAR ? "primary" : "grey"}
                        onClick={() => handleScaleChanged(ChartScaleOptions.LINEAR)}
                    >
                        Linear
                    </Button>
                </div>
            </div>

            <div className="toggle">
                <span>Chemical labels</span>
                <div className="toggle-buttons">
                    <Button
                        className="toggle-button"
                        variant={!chartOptions.showLabels ? "primary" : "grey"}
                        onClick={() => handleShowLabelsChanged(false)}
                    >
                        Hide
                    </Button>
                    <Button
                        className="toggle-button"
                        variant={chartOptions.showLabels ? "primary" : "grey"}
                        onClick={() => handleShowLabelsChanged(true)}
                    >
                        Display
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChartOptionsPanel;
