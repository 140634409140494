import React from "react";
import ConfirmDialog, { ConfirmationOptions } from ".";

interface ConfirmationResult {
    success: boolean;
}

const ConfirmationContext = React.createContext<(options: ConfirmationOptions) => Promise<ConfirmationResult>>(
    Promise.reject
);

export const useConfirmation = () => React.useContext(ConfirmationContext);

export const ConfirmationProvider = ({ children }: { children: React.ReactNode }) => {
    const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: (r: ConfirmationResult) => void;
        reject: () => void;
    }>();

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options);

        return new Promise<ConfirmationResult>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: false });
        }

        setConfirmationState(null);
    };

    const handleSubmit = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: true });
        }

        setConfirmationState(null);
    };

    const isOpen = Boolean(confirmationState);

    return (
        <>
            <ConfirmationContext.Provider value={openConfirmation}>{children}</ConfirmationContext.Provider>

            {isOpen && (
                <ConfirmDialog open={isOpen} onSubmit={handleSubmit} onClose={handleClose} {...confirmationState} />
            )}
        </>
    );
};
