import clsx from "clsx";
import { SelectOption } from "Components/Select/Select";
import { isNil } from "lodash";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./PTSelect.scss";
import PTSelectMenu from "./PTSelectMenu";
import PTSelectToggle from "./PTSelectToggle";

export interface PTSelectProps {
    values: SelectOption[];
    value: SelectOption;
    canSearch: boolean;
    className?: string;
    searchPlaceholder?: string;
    label?: string;
    onSelect: (selectedOption: SelectOption) => void;
}

const PTSelect = ({ values, value, className, canSearch, searchPlaceholder, label, onSelect }: PTSelectProps) => {
    const [search, setSearch] = useState<string>(null);

    const handleOptionSelected = (option: SelectOption) => {
        onSelect(option);
    };

    return (
        <Dropdown
            className={clsx("pt-select-container", className)}
            onToggle={(_, __, meta) => (meta.source === "rootClose" ? setSearch(null) : null)}
        >
            <Dropdown.Toggle as={PTSelectToggle} selectedOption={value} />

            <Dropdown.Menu
                as={PTSelectMenu}
                options={values}
                selectedOption={value}
                canSearch={canSearch}
                search={search}
                onSearch={setSearch}
                searchPlaceholder={searchPlaceholder}
                onOptionSelected={handleOptionSelected}
            />

            {!isNil(label) && <span className="pt-select-label">{label}</span>}
        </Dropdown>
    );
};

export default PTSelect;
