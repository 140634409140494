import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import Switch from "Components/Switch";
import TooltipIcon from "Components/TooltipIcon";
import React from "react";
import { ExploreConstituentModel } from "types/models";
import { useExplore } from "./ExploreContext";

export interface ConstituentsTableHeadProps {
    constituents: ExploreConstituentModel[];
}

const ConstituentsTableHead = ({ constituents }: ConstituentsTableHeadProps) => {
    const { updateConstituentsIsEnabled } = useExplore();

    const allSelected = constituents.every(c => c.isEnabled);

    const handleToggleAllSelected = (isChecked: boolean) => {
        updateConstituentsIsEnabled(constituents, isChecked);
    };

    return (
        <thead>
            <tr>
                <th></th>
                <th colSpan={2} className="centered-cell title-cell">
                    Default for active ingredient
                </th>
                <th colSpan={3} className="centered-cell title-cell blue-cell">
                    Your product
                </th>
                <th colSpan={2}></th>
            </tr>

            <tr>
                <th>Active ingredient</th>
                <th>
                    App rate (kg/ha or L/ha)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="The default application rate is the maximum application rate (kilograms per hectare, or litres per hectare) found across all researched product labels for the active ingredient. "
                        className="tooltip-icon"
                    />
                </th>
                <th>
                    Aquatic risk score
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="Indicates the likelihood that an active ingredient will reach a waterway and cause harmful effects. Calculated using the Relative Mobility or Persistence and Relative Measure of Effect based on the maximum application rate and broadcast application. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="blue-cell input-cell">
                    Concentration (g/L or g/kg)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="The concentration of the active ingredient (grams per litre, or grams per kilogram) as per your product’s label. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="blue-cell input-cell">
                    App rate (kg/ha or L/ha)
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="The application rate per hectare (kilograms or litres) of the product that you applied/wish to apply as part of your pest control regime. "
                        className="tooltip-icon"
                    />
                </th>
                <th className="blue-cell">
                    Aquatic risk score
                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="Indicates the likelihood that an active ingredient will reach a waterway and cause harmful effects. Calculated using the Relative Mobility or Persistence and Relative Measure of Effect based on the concentration and application rate you input. "
                        className="tooltip-icon"
                    />
                </th>
                <th>
                    <Switch checked={allSelected} onChecked={handleToggleAllSelected} />
                </th>
                <th>Add to a mix</th>
            </tr>
        </thead>
    );
};

export default ConstituentsTableHead;
