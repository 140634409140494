import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle as userIcon } from "@fortawesome/pro-solid-svg-icons";
import { faUserCircle as PendingInviteIcon } from "@fortawesome/pro-regular-svg-icons/faUserCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { fullname } from "utils/utils";
import Tooltip from "Components/Tooltip";

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { User, UserRole } from "types/models";

interface UserItemProps {
    user: User;
    isRemovable: boolean;
    onRemoved: (user: User) => void;
}

const UserItem = ({ user, isRemovable, onRemoved }: UserItemProps) => {
    const getIcon = (user: User): IconProp => {
        if (!user.verified) {
            return PendingInviteIcon;
        }

        return userIcon;
    };

    const getTooltip = (user: User): string => {
        switch (user.role) {
            case UserRole.USER:
                return "User";
            case UserRole.APP_ADMIN:
                return "Admin";
            case UserRole.ROOT:
                return "Root";
        }
    };

    const handleRemove = () => {
        onRemoved(user);
    };

    const displayedName = user.verified ? fullname(user) : user.email;
    const icon = getIcon(user);
    const tooltip = getTooltip(user);
    const isAdmin = user.role !== UserRole.USER;

    return (
        <div className={`${"group-user"} ${isAdmin ? "group-admin" : ""}`}>
            <Tooltip placement="bottom" tooltipContent={tooltip}>
                <FontAwesomeIcon icon={icon} />
            </Tooltip>

            <span>{displayedName}</span>

            {isRemovable && <FontAwesomeIcon icon={faTrashAlt} className="group-user-remove" onClick={handleRemove} />}
        </div>
    );
};

export default UserItem;
