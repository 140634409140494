import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import LoadingIndicator from "Components/LoadingIndicator";
import TooltipIcon from "Components/TooltipIcon";
import { isNil } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { useAssess } from "./AssessContext";
import { useMixView } from "./MixViewContext";

const MixViewHeaderStatusIcon = () => {
    const { mixes } = useAssess();
    const { mixId, isModified, isSaving } = useMixView();

    const mix = mixes.find(m => m.id === mixId);

    const lastSaved = useMemo(() => {
        if (isNil(mix)) {
            return;
        }

        return moment(mix.updatedAt).format("DD MMM, YYYY hh:mm A");
    }, [mix?.updatedAt]);

    return (
        <>
            {isSaving && <LoadingIndicator className="status-icon" size="2x" />}
            {!isSaving && (
                <>
                    {!isNil(mix) && !isModified && (
                        <TooltipIcon
                            tooltip={`Last saved - ${lastSaved}`}
                            icon={faCheckCircle}
                            className="status-icon saved-icon"
                            size="2x"
                        />
                    )}

                    {!isNil(mix) && isModified && (
                        <TooltipIcon
                            tooltip="This mix has been modified since last save"
                            icon={faExclamationTriangle}
                            className="status-icon warn-icon"
                            size="2x"
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MixViewHeaderStatusIcon;
