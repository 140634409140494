import React, { useState, useEffect, createContext, useContext } from "react";

interface Breakpoints {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

const queries = {
    xs: "(max-width: 575.98px)",
    sm: "(min-width: 576px)",
    md: "(min-width:  768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1200px)"
};

const defaultValue: Breakpoints = { xs: false, sm: false, md: false, lg: false, xl: false };

const BreakpointContext = createContext<Breakpoints>(defaultValue);

interface BreakpointProviderProps {
    children: React.ReactNode;
}

const BreakpointProvider = ({ children }: BreakpointProviderProps) => {
    const [queryMatch, setQueryMatch] = useState<Breakpoints | null>(null);

    useEffect(() => {
        const mediaQueryLists: Breakpoints = {
            xs: false,
            sm: false,
            md: false,
            lg: false,
            xl: false
        };

        const keys = Object.keys(queries);

        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce(
                (acc, media) => {
                    acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                    return acc;
                },
                { xs: false, sm: false, md: false, lg: false, xl: false }
            );

            setQueryMatch(updatedMatches);
        };

        if (window && window.matchMedia) {
            const matches: Breakpoints = { xs: false, sm: false, md: false, lg: false, xl: false };

            keys.forEach(media => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches;
                } else {
                    matches[media] = false;
                }
            });

            setQueryMatch(matches);

            isAttached = true;

            keys.forEach(media => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media].addListener(handleQueryListener);
                }
            });
        }

        return () => {
            if (isAttached) {
                keys.forEach(media => {
                    if (typeof queries[media] === "string") {
                        mediaQueryLists[media].removeListener(handleQueryListener);
                    }
                });
            }
        };
    }, []);

    return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
};

function useBreakpoint() {
    const breakpoints = useContext(BreakpointContext);

    if (breakpoints === defaultValue) {
        throw new Error("useBreakpoint must be used within BreakpointProvider");
    }

    return breakpoints ?? defaultValue;
}

export { useBreakpoint, BreakpointProvider };
