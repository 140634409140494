import React, { useEffect, useState } from "react";
import Input, { InputProps } from "Components/Input";
import { isNaN, isNil } from "lodash";
import { isNilOrEmpty } from "utils/utils";
import clsx from "clsx";

import "./NumericInput.scss";

export interface NumericInputProps extends Omit<InputProps, "onChange" | "value" | "type" | "min" | "max"> {
    value: number;
    min?: number;
    max?: number;
    unit?: string;
    containerClassName?: string;
    allowEmpty?: boolean;
    onChange?: (value: number) => void;
    onLoseFocus?: (value: number) => void;
}

export const getMinMax = (min: number, max: number): [number, number] => {
    const _min = !isNil(min) && isFinite(min) ? min : null;
    const _max = !isNil(max) && isFinite(max) ? max : null;

    return [_min, _max];
};

//TODO: Allow for only integer
const NumericInput = ({
    value,
    min,
    max,
    unit,
    containerClassName,
    className,
    allowEmpty = true,
    onChange,
    onLoseFocus,
    ...rest
}: NumericInputProps) => {
    const [textValue, setTextValue] = useState<string>(value?.toString());

    const handleBlur = () => {
        const [_min, _max] = getMinMax(min, max);

        let numberValue: number = !isNilOrEmpty(textValue) ? parseFloat(textValue) : null;

        if (!allowEmpty && isNil(numberValue)) {
            numberValue = value;
        }

        if (isNaN(numberValue)) {
            numberValue = value;
        }

        if (!isNil(_min) && numberValue < _min) {
            numberValue = min;
        }

        if (!isNil(_max) && numberValue > _max) {
            numberValue = max;
        }

        setTextValue(numberValue?.toString() ?? "");

        if (onChange && numberValue !== value) {
            onChange(numberValue);
        }

        if (onLoseFocus) {
            onLoseFocus(numberValue);
        }
    };

    useEffect(() => {
        setTextValue(value?.toString() ?? "");
    }, [value]);

    const hasUnit = !isNilOrEmpty(unit);

    return (
        <div className={clsx("erp-numeric-input-container", containerClassName)}>
            <Input
                type="text"
                value={textValue}
                onBlur={handleBlur}
                onChange={e => setTextValue(e.target.value)}
                className={clsx(className, { "with-unit": hasUnit })}
                {...rest}
            />

            {hasUnit && (
                <div className="unit">
                    <span>{unit}</span>
                </div>
            )}
        </div>
    );
};

export default NumericInput;
