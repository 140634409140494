import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";

import "./ERPIcon.scss";

export interface ERPIconProps extends FontAwesomeIconProps {
    disabled?: boolean;
    isLoading?: boolean;
}

const ERPIcon = ({ disabled, className, onClick, icon, isLoading, ...rest }: ERPIconProps) => {
    const handleClick = (e: React.MouseEvent<SVGSVGElement>) => {
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(e);
        }
    };

    const displayedIcon = !isLoading ? icon : faCircleNotch;

    return (
        <FontAwesomeIcon
            className={clsx(className, { "erp-icon--disabled": disabled === true })}
            onClick={handleClick}
            icon={displayedIcon}
            spin={isLoading}
            {...rest}
        />
    );
};

export default ERPIcon;
