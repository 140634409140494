import { CropConstituentModel, CropModel } from "types/models";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class CropService {
    public static async getCrops(): Promise<CropModel[]> {
        const crops = await API.get<CropModel[]>(`${URL.CROPS}`);

        return crops;
    }

    public static async getCropConstituents(id: string): Promise<CropConstituentModel[]> {
        const constituents = await API.get<CropConstituentModel[]>(`${URL.CROPS}/${id}/constituents`);

        return constituents;
    }
}
