import { MixModel } from "types/models";
import { CreateMixRequest, UpdateMixRequest } from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class MixService {
    public static async getMixes(): Promise<MixModel[]> {
        const mixes = await API.get<MixModel[]>(`${URL.MIXES}`);

        return mixes;
    }

    public static async createMix(request: CreateMixRequest): Promise<MixModel> {
        const mix = await API.post<MixModel>(`${URL.MIXES}`, request);

        return mix;
    }

    public static async updateMix(mixId: string, request: UpdateMixRequest): Promise<MixModel> {
        const mix = await API.patch<MixModel>(`${URL.MIXES}/${mixId}`, request);

        return mix;
    }

    public static async deleteMix(mixId: string): Promise<void> {
        await API.delete<void>(`${URL.MIXES}/${mixId}`);
    }
}
