import React from "react";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { ModalProps } from "./types";

import "./ERPModal.scss";

interface ERPModalProps extends ModalProps {
    children: React.ReactNode;
    className?: string;
    contentClassName?: string;
    canClose?: boolean;
}

const ERPModal = ({
    show,
    title,
    children,
    size,
    scrollable,
    bodyClassName,
    centered,
    backdrop,
    onClose,
    footer,
    footerClassName,
    backdropClassName,
    dialogClassName,
    className,
    contentClassName,
    canClose = true
}: ERPModalProps) => {
    const handleHide = () => {
        if (canClose) {
            onClose();
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleHide}
            size={size}
            scrollable={scrollable}
            centered={centered}
            backdrop={backdrop}
            backdropClassName={backdropClassName}
            dialogClassName={dialogClassName}
            className={className}
            contentClassName={contentClassName}
        >
            <Modal.Header closeButton={false} className="modal--header">
                <div className="header-title">
                    <span>{title}</span>
                </div>

                {canClose && <FontAwesomeIcon icon={faTimesCircle} onClick={onClose} />}
            </Modal.Header>

            <Modal.Body className={bodyClassName}>{children}</Modal.Body>

            {footer && <Modal.Footer className={footerClassName}>{footer}</Modal.Footer>}
        </Modal>
    );
};

export default ERPModal;
