import React from "react";
import { ExploreConstituentModel } from "types/models";
import ConstituentsTableBodyRow from "./ConstituentsTableBodyRow";

export interface ConstituentsTableBodyProps {
    constituents: ExploreConstituentModel[];
}

const ConstituentsTableBody = ({ constituents }: ConstituentsTableBodyProps) => {
    return (
        <tbody>
            {constituents.map(constituent => {
                return <ConstituentsTableBodyRow constituent={constituent} key={constituent.id} />;
            })}
        </tbody>
    );
};

export default ConstituentsTableBody;
