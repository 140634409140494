import { ChartDataset, ChartOptions } from "chart.js";
import PTChart from "Components/Charts/PTChart";
import { round } from "lodash";
import React, { useMemo } from "react";
import { useMixView } from "./MixViewContext";

const CHART_HEIGHT = 100;

const CHART_COLOUR_OPTIONS = [
    "#FF671B",
    "#08BFDD",
    "#E24999",
    "#FDCD06",
    "#77BC1F",
    "#D2272E",
    "#0076BB",
    "#8547AD",
    "#FFA400",
    "#00994D"
];

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    indexAxis: "y",
    scales: {
        x: {
            display: false,
            min: 0,
            max: 100,
            stacked: true
        },
        y: {
            display: false,
            stacked: true
        }
    },
    layout: {
        padding: 0
    },
    plugins: {
        tooltip: {
            enabled: true,
            displayColors: false,
            intersect: true,
            mode: "point",
            position: "stackedBarPositioner",
            callbacks: {
                title: function (tooltipItems) {
                    const tooltipItem = tooltipItems[0];

                    return tooltipItem.dataset.label;
                },
                label: function (tooltipItem) {
                    return `Risk proportion: ${round(+tooltipItem.formattedValue, 0)}%`;
                }
            }
        },
        datalabels: {
            display: false
        },
        legend: {
            display: false
        },
        title: {
            display: false
        }
    },
    hover: {
        mode: "index",
        intersect: false
    },
    animation: false
};

const MixViewResultChart = () => {
    const { constituents, mixRiskScore, isPrimaryMix } = useMixView();

    const data: ChartDataset[] = useMemo(() => {
        const sortedConstituents = [...constituents.filter(c => c.isEnabled)].sort((a, b) =>
            a.appliedRiskUnits <= b.appliedRiskUnits ? 1 : -1
        );

        return sortedConstituents.map((c, i) => {
            return {
                label: c.name,
                data: [(c.appliedRiskUnits / mixRiskScore) * 100],
                backgroundColor: CHART_COLOUR_OPTIONS[i % CHART_COLOUR_OPTIONS.length]
            };
        });
    }, [constituents]);

    return (
        <div className="chart-container" style={{ marginTop: isPrimaryMix ? 80 : 50 }}>
            <span className="chart-label">Proportional contribution of chemicals in your mix to aquatic risk</span>
            <PTChart type="bar" data={data} labels={["data"]} options={CHART_OPTIONS} height={CHART_HEIGHT} />
        </div>
    );
};

export default MixViewResultChart;
