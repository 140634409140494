import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from "react-router-dom";
import AppRoutes from "./Routes/app-routes";
import Header from "Components/Header/Header";
import { useAuth0 } from "auth/react-auth0-spa";
import LoadingIndicator from "Components/LoadingIndicator";

import "./App.scss";

function App() {
    const { isAuthenticated, loading, askForLogin } = useAuth0();

    if (loading) {
        return <LoadingIndicator centered className="app-loader" />;
    }

    return (
        <div className="app">
            <Helmet titleTemplate="%s | Pesticide Projector" defaultTitle="Pesticide Projector"></Helmet>

            <BrowserRouter>
                {isAuthenticated && <Header />}

                <div className="content">
                    <Switch>
                        {Object.keys(AppRoutes).map(routeKey => {
                            const route = AppRoutes[routeKey];

                            return (
                                <Route
                                    key={routeKey}
                                    exact={route.exact}
                                    path={route.route}
                                    render={(props: RouteProps) => {
                                        if (route.authenticated && !isAuthenticated) {
                                            askForLogin("", { backTo: props?.location?.pathname });
                                            return null;
                                        }

                                        return <route.scene {...props} />;
                                    }}
                                />
                            );
                        })}

                        <Redirect to="/explore" />
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
