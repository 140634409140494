import React from "react";
import clsx from "clsx";

import "./Label.scss";

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

const Label = ({ className, children, ...rest }: LabelProps) => {
    return (
        <label className={clsx("erp-label", className)} {...rest}>
            {children}
        </label>
    );
};

export default Label;
