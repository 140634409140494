import { isNil } from "lodash";
import React, { useEffect } from "react";
import { useAssess } from "./AssessContext";
import MixViewBody from "./MixViewBody";
import { useMixView } from "./MixViewContext";
import MixViewHeader from "./MixViewHeader";

const MixView = () => {
    const { updatePrimaryMixScore } = useAssess();
    const { mixId, mixRiskScore, isComparing, isPrimaryMix, isModified } = useMixView();

    useEffect(() => {
        if (isPrimaryMix) {
            updatePrimaryMixScore(mixRiskScore);
        }
    }, [mixRiskScore, isPrimaryMix]);

    useEffect(() => {
        window.onbeforeunload = () => {
            if (isModified) {
                return "Unsaved changed";
            }
        };
    }, [isModified]);

    return (
        <div className={!isComparing ? "mix-view-container" : "mix-view-container-compare"}>
            <MixViewHeader />

            {!isNil(mixId) && <MixViewBody />}
        </div>
    );
};

export default MixView;
