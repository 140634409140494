import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { isNil } from "lodash";
import React from "react";
import "./Button.scss";

export type ButtonProps = {
    variant?: "primary" | "secondary" | "grey" | "none" | "attention" | "green" | "blue";
    rounded?: boolean;
    icon?: IconProp;
    iconPlacement?: "before" | "after";
    iconClassName?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = (props: ButtonProps) => {
    const {
        type,
        variant = "primary",
        rounded,
        className,
        children,
        icon,
        iconPlacement = "after",
        iconClassName,
        ...rest
    } = props;

    return (
        <button
            type={type ?? "button"}
            className={clsx(`erp-button`, className, {
                primary: variant === "primary",
                secondary: variant === "secondary",
                grey: variant === "grey",
                none: variant === "none",
                rounded: rounded,
                attention: variant === "attention",
                green: variant === "green",
                blue: variant === "blue"
            })}
            {...rest}
        >
            {!isNil(icon) && iconPlacement === "before" && (
                <FontAwesomeIcon icon={icon} className={clsx("btn-icon-before", iconClassName)} />
            )}

            {children}

            {!isNil(icon) && iconPlacement === "after" && (
                <FontAwesomeIcon icon={icon} className={clsx("btn-icon-after", iconClassName)} />
            )}
        </button>
    );
};

export default Button;
