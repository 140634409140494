import clsx from "clsx";
import { SelectOption } from "Components/Select/Select";
import React from "react";

export interface PTSelectItemProps {
    option: SelectOption;
    isSelected: boolean;
    onOptionSelected: (next: SelectOption) => void;
    onClick?: (e: any) => void;
}

const PTSelectItem = React.forwardRef((props: PTSelectItemProps, ref: any) => {
    const { option, isSelected, onOptionSelected, onClick } = props;

    return (
        <div
            className={clsx("pt-select-item", isSelected ? "pt-selected-item" : null)}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onOptionSelected(option);
                onClick(e);
            }}
        >
            {option.label}
        </div>
    );
});

export default PTSelectItem;
