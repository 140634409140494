import NavigationTabs, { NavigationTabType } from "Components/NavigationTabs";
import PageTitle from "Components/PageTitle";
import React from "react";
import ExploreBody from "./ExploreBody";
import ExploreContextProvider from "./ExploreContext";

import "./Explore.scss";
import Filter from "./Filter";

const Explore = () => {
    return (
        <ExploreContextProvider>
            <div className="explore-container">
                <PageTitle title="Explore" />

                <NavigationTabs selectedTab={NavigationTabType.EXPLORE} />

                <Filter />

                <ExploreBody />
            </div>
        </ExploreContextProvider>
    );
};

export default Explore;
