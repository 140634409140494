import React from "react";
import { roundToString } from "utils/utils";

export interface ConstituentInfoBoxProps {
    aquaticRisk: number;
    measureOfEffect: number;
    measureOfMobilityAndPersistence: number;
}

const ConstituentInfoBox = ({
    aquaticRisk,
    measureOfEffect,
    measureOfMobilityAndPersistence
}: ConstituentInfoBoxProps) => {
    return (
        <div className="constituent-info-box">
            <div className="info-line">
                <span>Aquatic risk score:</span>
                <span className="info-value">{roundToString(aquaticRisk, 0)}</span>
            </div>
            <div className="info-line">
                <span>Toxicity (measure of effect):</span>
                <span className="info-value">{roundToString(measureOfEffect, 1)}</span>
            </div>
            <div className="info-line">
                <span>Runoff likelihood (measure of mobility and persistence):</span>
                <span className="info-value">{roundToString(measureOfMobilityAndPersistence, 1)}</span>
            </div>
        </div>
    );
};

export default ConstituentInfoBox;
