import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "Components/ERPIcon";
import React from "react";

const HowToPanel = () => {
    return (
        <div className="how-to-panel">
            <ERPIcon icon={faInfoCircle} className="info-icon" size="2x" />
            <div className="info-text">
                Using the list below, select active ingredients to highlight them on the chart, view more information
                about them, edit their concentrations according to product labels, edit the application rate as per your
                use, and show/hide them on the chart. You can also add active ingredients to your application mix to
                assess the aquatic risk of your whole application mix.
            </div>
        </div>
    );
};

export default HowToPanel;
