import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface LabelHeaderContentProps {
    label: string;
    icon: IconProp;
    iconClassName: string;
}

const LabelHeaderContent = ({ label, icon, iconClassName }: LabelHeaderContentProps) => {
    return (
        <div className="header-content label-content">
            <div className="header-label">
                <FontAwesomeIcon className={iconClassName} icon={icon} size="lg" />

                <span>{label}</span>
            </div>
        </div>
    );
};

export default LabelHeaderContent;
