import clsx from "clsx";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import React from "react";
import { useHistory } from "react-router-dom";
import "./NavigationTabs.scss";

export enum NavigationTabType {
    EXPLORE = "explore",
    ASSESS = "assess"
}

interface NavigationTabsProps {
    selectedTab: NavigationTabType;
    hasUnsavedChages?: boolean;
}

const NavigationTabs = ({ selectedTab, hasUnsavedChages }: NavigationTabsProps) => {
    const history = useHistory();
    const confirm = useConfirmation();

    const handleTabClick = async (tabType: NavigationTabType) => {
        if (selectedTab === tabType) {
            return;
        }

        if (hasUnsavedChages) {
            const result = await confirm({
                title: "Unsaved changes",
                description: `Unsaved changes will be lost. Do you wish to close without saving?`
            });

            if (!result.success) {
                return;
            }
        }

        switch (tabType) {
            case NavigationTabType.EXPLORE:
                history.push("/explore");
                break;

            case NavigationTabType.ASSESS:
                history.push("/assess");
                break;

            default:
                history.push("/");
                break;
        }
    };

    return (
        <div className="navigation-tabs">
            <div
                className={clsx("navigation-tab", { selected: selectedTab === NavigationTabType.EXPLORE })}
                onClick={() => handleTabClick(NavigationTabType.EXPLORE)}
            >
                <span className="navigation-tab-label">Explore the aquatic risk of chemicals</span>
            </div>
            <div
                className={clsx("navigation-tab", { selected: selectedTab === NavigationTabType.ASSESS })}
                onClick={() => handleTabClick(NavigationTabType.ASSESS)}
            >
                <span className="navigation-tab-label">Assess the aquatic risk of an application mix</span>
            </div>
        </div>
    );
};

export default NavigationTabs;
