import React, { useContext, useEffect, useState } from "react";
import { MixModel } from "types/models";
import MixService from "Services/mix.service";
import LoadingIndicator from "Components/LoadingIndicator";

interface AssessContextProps {
    mixes: MixModel[];
    primaryMixScore: number;
    updateMixes: (nextMixes: MixModel[]) => void;
    updatePrimaryMixScore: (nextPrimaryMixScore: number) => void;
    updateHasUnsavedChanges: (nextHasUnsavedChanges: boolean) => void;
}

interface AssessContextOptions {
    updateHasUnsavedChanges: (nextHasUnsavedChanges: boolean) => void;
    children: React.ReactNode;
}

export const AssessContext = React.createContext<AssessContextProps | null>(null);

export const useAssess = () => useContext(AssessContext);

const AssessContextProvider = ({ updateHasUnsavedChanges, children }: AssessContextOptions) => {
    const [mixes, setMixes] = useState<MixModel[]>([]);
    const [primaryMixScore, setPrimaryMixScore] = useState<number>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const _mixes = await MixService.getMixes();

                setMixes(_mixes);
            } finally {
                setIsLoading(false);
            }
        };

        getData();
    }, []);

    const updateMixes = (nextMixes: MixModel[]) => {
        setMixes(nextMixes);
    };

    const updatePrimaryMixScore = (nextPrimaryMixScore: number) => {
        setPrimaryMixScore(nextPrimaryMixScore);
    };

    return (
        <AssessContext.Provider
            value={{
                mixes: mixes,
                primaryMixScore: primaryMixScore,
                updateMixes: updateMixes,
                updatePrimaryMixScore: updatePrimaryMixScore,
                updateHasUnsavedChanges: updateHasUnsavedChanges
            }}
        >
            {isLoading && <LoadingIndicator className="assess-loading" centered />}

            {!isLoading && children}
        </AssessContext.Provider>
    );
};

export default AssessContextProvider;
