import React from "react";
import Humanize from "humanize-plus";

export interface ConstituentInfoActiveIngredient {
    pesticideType: string;
    casNo: string;
    chemicalGroup: string;
}

const ConstituentInfoActiveIngredient = ({ pesticideType, casNo, chemicalGroup }: ConstituentInfoActiveIngredient) => {
    return (
        <div className="active-ingredient-container">
            <span className="title">Active ingredient information</span>
            <div className="active-ingredient-info">
                <div className="info-line" style={{ gridRow: 1, gridColumn: 1 }}>
                    <span>Class:</span>
                    <span className="info-value">{Humanize.capitalize(pesticideType)}</span>
                </div>

                <div className="info-line" style={{ gridRow: 1, gridColumn: 2 }}>
                    <span>CAS No.:</span>
                    <span className="info-value">{casNo}</span>
                </div>

                <div className="info-line" style={{ gridRow: 2, gridColumnStart: 1, gridColumnEnd: 3 }}>
                    <span>Chemical group:</span>
                    <span className="info-value">{chemicalGroup}</span>
                </div>
            </div>
        </div>
    );
};

export default ConstituentInfoActiveIngredient;
