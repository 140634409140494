import React from "react";
import Button, { ButtonProps } from ".";

import "./Button.scss";

interface ActivableButtonProps extends ButtonProps {
    isActive?: boolean;
}

const ActivableButton = ({ isActive, className, ...rest }: ActivableButtonProps) => {
    return <Button variant="none" className={`${className} activable-button ${isActive ? "active" : ""}`} {...rest} />;
};

export default ActivableButton;
