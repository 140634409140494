import { useAuth0 } from "auth/react-auth0-spa";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import { Toast } from "Components/Toast";
import React, { useEffect, useState } from "react";
import UserService from "Services/user.service";
import { User, UserRole } from "types/models";
import { fullname } from "utils/utils";
import UserInviteInput from "./UserInviteInput";
import UserItem from "./UserItem";

const USER_SORT_ORDER = [UserRole.ROOT, UserRole.APP_ADMIN, UserRole.USER];

const ManageUsers = () => {
    const { ptUser } = useAuth0();

    const [users, setUsers] = useState<User[]>([]);
    const [isAddingUser, setIsAddingUser] = useState<boolean>(false);

    const confirm = useConfirmation();

    useEffect(() => {
        const getUsers = async () => {
            const users = await UserService.getUsers();

            const sortedUsers = users.sort((a, b) => {
                const indexA = USER_SORT_ORDER.indexOf(a.role);
                const indexB = USER_SORT_ORDER.indexOf(b.role);
                return indexA - indexB;
            });

            setUsers(sortedUsers);
        };

        getUsers();
    }, []);

    const handleUserRemoved = async (user: User) => {
        const displayedUser = user.verified ? fullname(user) : user.email;

        const result = await confirm({
            title: "Remove User",
            description: `Are you sure you want to remove ${displayedUser}?`
        });

        if (!result.success) {
            return;
        }

        await UserService.deleteUser(user.id);

        const next = users.filter(u => u.id !== user.id);

        setUsers(next);

        Toast.success("Successfully removed user");
    };

    const handleUserAdded = async (email: string, role: UserRole) => {
        if (users.some(u => u.normalisedEmail === email)) {
            Toast.error("A user already exists with this email");
            return;
        }

        try {
            setIsAddingUser(true);
            const user = await UserService.createUser(email, role);
            setUsers([...users, user]);
            Toast.success("Successfully added user");
        } finally {
            setIsAddingUser(false);
        }
    };

    return (
        <div className="manage-users">
            <div className="group-users">
                {users.map(user => {
                    const isCurrentUser = ptUser.id === user.id;
                    const isRemovable = ptUser.role === UserRole.ROOT ? true : user.role === UserRole.USER;

                    return (
                        <UserItem
                            key={user.id}
                            user={user}
                            isRemovable={isRemovable && !isCurrentUser}
                            onRemoved={handleUserRemoved}
                        />
                    );
                })}
            </div>

            <div className="group-users-input">
                <UserInviteInput onUserAdded={handleUserAdded} isAddingUser={isAddingUser} />
            </div>
        </div>
    );
};

export default ManageUsers;
