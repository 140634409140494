import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import ERPIcon from "Components/ERPIcon";
import MixSelect, { MixSelectStyle } from "Components/MixSelect";
import { isNil } from "lodash";
import React from "react";
import { useAssess } from "./AssessContext";
import { useMixView } from "./MixViewContext";
import MixViewHeaderStatusIcon from "./MixViewHeaderStatusIcon";

const MixViewHeader = () => {
    const { mixes, updateMixes } = useAssess();
    const { mixId, isComparing, updateMixId, updateCompareMixId, onMixClosed } = useMixView();

    return (
        <div className="header">
            <div className="assess-mix-select-container">
                <span className="mix-select-text">Aquatic risk of</span>
                <MixSelect
                    mixes={mixes}
                    selectedMixId={mixId}
                    style={MixSelectStyle.DROPDOWN}
                    onMixSelected={updateMixId}
                    updateMixes={updateMixes}
                />
                <MixViewHeaderStatusIcon />
            </div>

            {!isComparing && (
                <MixSelect
                    mixes={mixes}
                    style={MixSelectStyle.COMPARE}
                    disabled={isNil(mixId)}
                    onMixSelected={updateCompareMixId}
                    updateMixes={updateMixes}
                />
            )}

            {isComparing && (
                <ERPIcon icon={faTimesCircle} size="2x" className="close-icon" onClick={() => onMixClosed()} />
            )}
        </div>
    );
};

export default MixViewHeader;
