import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import Input from "Components/Input";
import { SelectOption } from "Components/Select/Select";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { isNilOrEmpty } from "utils/utils";
import PTSelectItem from "./PTSelectItem";

export interface PTSelectMenuProps {
    options: SelectOption[];
    selectedOption: SelectOption;
    canSearch: boolean;
    search: string;
    searchPlaceholder?: string;
    onSearch: (next: string) => void;
    onOptionSelected: (next: SelectOption) => void;
    show: boolean;
    style?: any;
}

const PTSelectMenu = React.forwardRef((props: PTSelectMenuProps, ref: any) => {
    const { options, selectedOption, canSearch, search, searchPlaceholder, onSearch, onOptionSelected, style, show } =
        props;

    const optionsToShow = useMemo(() => {
        if (isNilOrEmpty(search)) {
            return options;
        }

        const searchNormalised = search.toLowerCase();
        return options.filter(v => {
            const valueNormalised = v.value.toLowerCase();
            return valueNormalised.includes(searchNormalised);
        });
    }, [options, search]);

    const handleOptionSelected = (option: SelectOption) => {
        onSearch(null);

        if (option.value === selectedOption?.value) {
            return;
        }

        onOptionSelected(option);
    };

    return (
        <>
            {show && (
                <div className="pt-select-menu" ref={ref} style={style}>
                    {canSearch && (
                        <Input
                            key={!isNil(search) ? "search-box-searching" : "search-box-empty"}
                            autoFocus={true}
                            value={search}
                            onChange={e => onSearch(e.target.value)}
                            className="pt-select-search-box"
                            placeholder={searchPlaceholder}
                            icon={faSearch}
                        />
                    )}

                    {optionsToShow.map(o => {
                        return (
                            <Dropdown.Item
                                key={o.value}
                                as={PTSelectItem}
                                option={o}
                                isSelected={selectedOption?.value === o.value}
                                onOptionSelected={handleOptionSelected}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
});

export default PTSelectMenu;
