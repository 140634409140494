import { faSortDown } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "Components/ERPIcon";
import { isNil } from "lodash";
import React from "react";
import { MixModel } from "types/models";

export interface MixSelectDropdownToggleProps {
    selectedMix: MixModel;
    onClick: (e: any) => void;
}

const MixSelectDropdownToggle = React.forwardRef(({ selectedMix, onClick }: MixSelectDropdownToggleProps, ref: any) => {
    const toggleText = !isNil(selectedMix) ? selectedMix.name : "Load a mix";

    return (
        <div
            className="mix-select-toggle-dropdown"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            ref={ref}
        >
            <span className="selected-option">{toggleText}</span>
            <ERPIcon className="open-arrow" icon={faSortDown} size="2x"></ERPIcon>
        </div>
    );
});

export default MixSelectDropdownToggle;
