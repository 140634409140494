import React from "react";
import { MixConstituentModel, MixModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "../../Components/FormActions";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import FormInput from "../../Components/FormInput/FormInput";
import { Toast } from "../../Components/Toast";
import { CreateMixRequest, SaveNewMixConstituentsRequest, UpdateMixRequest } from "types/requests";
import MixService from "Services/mix.service";
import MixConstituentService from "Services/mix-constituent.service";

interface MixModalProps extends ModalProps {
    context: MixModalContext;
    onMixEdit?: (nextMix: MixModel) => void;
    onMixCreated?: (mix: MixModel) => void;
}

export enum MixContextType {
    CREATE = "create",
    SAVE_AS = "save_as",
    EDIT = "edit"
}

export interface MixModalContext {
    type: MixContextType;
    mix?: MixModel;
    mixConstituents?: MixConstituentModel[];
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface MixForm {
    name: string;
}

const MixModal = ({ context, show, onClose, onMixEdit, onMixCreated }: MixModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<MixForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context?.mix?.name ?? "New Mix"
        }
    });

    const handleFormSubmit = async (data: MixForm) => {
        switch (context.type) {
            case MixContextType.EDIT:
                handleEditMix(data.name);
                break;

            case MixContextType.CREATE:
                handleCreateMix(data.name);
                break;

            case MixContextType.SAVE_AS:
                handleSaveAsMix(data.name);
                break;

            default:
                break;
        }

        onClose();
    };

    const handleCreateMix = async (name: string) => {
        const request: CreateMixRequest = {
            name: name
        };

        const newMix = await MixService.createMix(request);

        Toast.success(`Successfully created ${name}`);

        onMixCreated(newMix);
    };

    const handleEditMix = async (name: string) => {
        const request: UpdateMixRequest = {
            name: name
        };

        const updatedMix = await MixService.updateMix(context.mix.id, request);

        Toast.success(`Successfully updated ${name}`);

        onMixEdit(updatedMix);
    };

    const handleSaveAsMix = async (name: string) => {
        const request: SaveNewMixConstituentsRequest = {
            name: name,
            constituents: context.mixConstituents.map(c => {
                return {
                    constituentId: c.id,
                    concentration: c.concentration,
                    applicationRate: c.applicationRate,
                    sprayMethod: c.sprayMethod,
                    isEnabled: c.isEnabled,
                    cropId: c.cropId
                };
            })
        };

        const newMix = await MixConstituentService.saveNewMixConstituent(request);

        Toast.success(`Successfully created ${name}`);

        onMixCreated(newMix);
    };

    return (
        <ERPModal
            title={`${context.type === MixContextType.EDIT ? "Edit" : "Create"} mix`}
            show={show}
            onClose={onClose}
        >
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Folder name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton isLoading={isSubmitting} variant="primary" type="submit">
                            {`${context.type === MixContextType.EDIT ? "Update" : "Create"}`}
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default MixModal;
