import React, { ChangeEvent, useEffect, useState } from "react";

import "./Switch.scss";

type SwitchProps = Omit<React.LabelHTMLAttributes<HTMLLabelElement>, "children" | "className"> & {
    checked: boolean;
    disabled?: boolean;
    onChecked: (checked: boolean) => void;
};

const Switch = ({ checked, onChecked, disabled, ...rest }: SwitchProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChange = (e: ChangeEvent) => {
        if (disabled) {
            return;
        }

        e.stopPropagation();

        const next = !isChecked;

        setIsChecked(next);

        if (onChecked) {
            onChecked(next);
        }
    };

    return (
        <label className="erp-switch" {...rest}>
            <input type="checkbox" checked={isChecked} className="erp-switch--input" onChange={handleChange} />
            <span className="erp-switch-slider"></span>
        </label>
    );
};

export default Switch;
