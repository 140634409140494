import { isNil, round } from "lodash";
import Humanize from "humanize-plus";

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const sleep = (time = 2000) => {
    return new Promise(resolve => {
        setTimeout(resolve, time);
    });
};

export const tryParseFloat = (val: string): number => {
    if (isNil(val)) {
        return null;
    }

    try {
        const time = parseFloat(val);

        if (isNaN(time)) {
            return null;
        }

        return time;
    } catch (error) {
        return null;
    }
};

export function fullnameFromString(firstName?: string, lastName?: string): string {
    return `${firstName} ${lastName}`;
}

export function fullname<T extends { firstName?: string; lastName?: string }>(obj: T): string {
    if (isNil(obj)) {
        return "";
    }

    return `${obj.firstName} ${obj.lastName}`;
}

export function isNilOrEmpty<T>(val: string | T[]) {
    return isNil(val) || val.length === 0;
}

export function isValidEmail(email: string): boolean {
    return /^[a-zA-Z0-9].+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email);
}

export const roundToString = (value: number, dp: number): string => {
    if (value < 1 && value > 0) {
        return "<1";
    }

    return Humanize.intComma(round(value, dp));
};
