import Button from "Components/Button";
import React from "react";

export interface MixSelectCompareToggleProps {
    disabled?: boolean;
    onClick: (e: any) => void;
}

const MixSelectCompareToggle = React.forwardRef(({ disabled, onClick }: MixSelectCompareToggleProps, ref: any) => {
    return (
        <div
            className="mix-select-toggle-compare"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            ref={ref}
        >
            <Button className="mix-compare-button" variant="green" disabled={disabled}>
                Compare with another mix
            </Button>
        </div>
    );
});

export default MixSelectCompareToggle;
