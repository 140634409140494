import React from "react";
import { useMixView } from "./MixViewContext";
import MixViewActions from "./MixViewActions";
import MixViewTableBody from "./MixViewTableBody";
import MixViewTableHead from "./MixViewTableHead";
import ERPIcon from "Components/ERPIcon";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";

const MixViewTable = () => {
    const { constituents } = useMixView();

    return (
        <div className="table-info-container">
            <div className="header-container">
                <span className="table-label">{`Active ingredients (${constituents.length} selected)`}</span>
                <MixViewActions />
            </div>

            <div className="table-container">
                <table>
                    <MixViewTableHead />

                    <MixViewTableBody />
                </table>
            </div>

            <div className="add-constituents-container">
                <ERPIcon icon={faPlusCircle} className="add-icon" size="lg" />
                <Link to="/explore">
                    <span className="add-constituents-label">
                        Add active ingredients to your mix using the table in the explore page
                    </span>
                </Link>
            </div>
        </div>
    );
};

export default MixViewTable;
