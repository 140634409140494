import FormError from "Components/FormError/FormError";
import Label from "Components/Label";
import { isNil } from "lodash";
import React, { useState, useEffect, ComponentType, KeyboardEventHandler, FocusEventHandler } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import Select, { ActionMeta, components, InputActionMeta, OptionProps } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { guid } from "utils/utils";

export type SelectOption = { value: string; label: string; [key: string]: any };

export type StatefulSelectOption = {
    selected: boolean;
} & SelectOption;

export interface SelectProps {
    id?: string;
    name?: string;
    labelText?: string;
    labelClassName?: string;
    initialValue?: SelectOption | SelectOption[];
    values: Array<SelectOption>;
    onSelected?: (value: SelectOption | SelectOption[], action: ActionMeta<SelectOption | SelectOption[]>) => void;
    placeholder?: string;
    disabled?: boolean;
    menuClassName?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    closeMenuOnSelect?: boolean;
    register?: UseFormRegisterReturn;
    value?: SelectOption | SelectOption[];
    renderOption?: ComponentType<OptionProps<any, any, any>>;
    onKeyDown?: KeyboardEventHandler;
    inputValue?: string;
    onInputChange?: (newValue: string, action: InputActionMeta) => void;
    hideMenuIcon?: boolean;
    isLoading?: boolean;
    clearValueOnSelect?: boolean;
    isMulti?: boolean;
    error?: FieldError;
    filterOption?: (option: FilterOptionOption<SelectOption>, value: string) => boolean;
    tabSelectsValue?: boolean;
    menuIsOpen?: boolean;
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    openMenuOnClick?: boolean;
}

const ERROR_COLOR = "#d2272e";
const PRIMARY_COLOR = "#08bfdd";
const PRIMARY_COLOR_25 = "#08bfdd";
const WHITE_COLOR = "white";
const DISABLED_GREY = "#efefef";
const DISABLED_GREY_INDICATOR = "#d1d3d4";
const GREY = "#acacac";

const ERPSelect = React.forwardRef((props: SelectProps, ref: any) => {
    const {
        id = guid(),
        name = `select-${guid()}`,
        labelText,
        labelClassName,
        initialValue,
        values,
        onSelected,
        placeholder,
        disabled,
        menuClassName,
        isClearable = false,
        isSearchable = true,
        register,
        value,
        closeMenuOnSelect,
        renderOption = components.Option,
        hideMenuIcon = false,
        clearValueOnSelect,
        error,
        filterOption,
        tabSelectsValue,
        menuIsOpen,
        onFocus,
        onBlur,
        onMenuOpen,
        onMenuClose,
        openMenuOnClick,
        ...rest
    } = props;

    const [selectedValue, setSelectedValue] = useState<SelectOption | SelectOption[]>(initialValue);

    useEffect(() => {
        setSelectedValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const handleSelectedValue = (
        value: SelectOption | SelectOption[],
        action: ActionMeta<SelectOption | SelectOption[]>
    ) => {
        setSelectedValue(!clearValueOnSelect ? value : null);

        if (onSelected) {
            onSelected(value, action);
        }
    };

    const hasError = !isNil(error);

    return (
        <div className={menuClassName}>
            {labelText && (
                <Label htmlFor={name} className={labelClassName}>
                    {labelText}
                </Label>
            )}

            <Select
                ref={ref}
                id={id}
                name={name}
                isSearchable={isSearchable}
                isClearable={isClearable}
                placeholder={placeholder}
                value={value ?? selectedValue}
                onChange={handleSelectedValue}
                tabSelectsValue={tabSelectsValue}
                options={values}
                filterOption={filterOption}
                onFocus={onFocus}
                onBlur={onBlur}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                openMenuOnClick={openMenuOnClick}
                closeMenuOnSelect={closeMenuOnSelect}
                menuIsOpen={menuIsOpen}
                menuPlacement="auto"
                menuPosition="fixed"
                theme={theme => {
                    return {
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: PRIMARY_COLOR,
                            primary25: PRIMARY_COLOR_25
                        }
                    };
                }}
                isDisabled={disabled}
                components={{
                    Option: renderOption,
                    IndicatorsContainer: hideMenuIcon ? () => null : components.IndicatorsContainer
                }}
                styles={{
                    control: provided => {
                        const borderColor = hasError ? ERROR_COLOR : disabled ? DISABLED_GREY_INDICATOR : GREY;
                        const hoverBorderColor = hasError ? ERROR_COLOR : GREY;

                        return {
                            ...provided,
                            borderWidth: "1px",
                            borderColor: borderColor,
                            borderRadius: "5px",
                            ":hover": { borderColor: hoverBorderColor }
                        };
                    },
                    loadingIndicator: provided => {
                        return {
                            ...provided,
                            backgroundColor: WHITE_COLOR
                        };
                    },
                    dropdownIndicator: provided => {
                        return {
                            ...provided,
                            color: !disabled ? GREY : DISABLED_GREY_INDICATOR,
                            backgroundColor: !disabled ? WHITE_COLOR : DISABLED_GREY,
                            borderRadius: "5px",
                            height: "100%",
                            alignItems: "center",
                            ":hover": {
                                ...provided[":hover"],
                                color: PRIMARY_COLOR,
                                cursor: "pointer"
                            }
                        };
                    },
                    indicatorSeparator: provided => {
                        return {
                            ...provided,
                            backgroundColor: "transparent"
                        };
                    },
                    option: provided => {
                        return {
                            ...provided,
                            ":active": {
                                backgroundColor: PRIMARY_COLOR,
                                color: WHITE_COLOR
                            },
                            ":hover": {
                                ...provided[":hover"],
                                color: WHITE_COLOR
                            }
                        };
                    }
                }}
                {...register}
                {...rest}
            />

            {hasError && <FormError error={error} />}
        </div>
    );
});

export default ERPSelect;
