import { faCircleNotch, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "auth/react-auth0-spa";
import Button from "Components/Button";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { UserRole } from "types/models";

interface UserRolePickerProps {
    isAddingUser: boolean;
    onRoleSelected: (role: UserRole) => void;
}

const UserRolePicker = ({ isAddingUser, onRoleSelected }: UserRolePickerProps) => {
    const { ptUser } = useAuth0();

    return (
        <Dropdown>
            <Dropdown.Toggle className="menu-toggle">
                {!isAddingUser && <FontAwesomeIcon icon={faUserPlus} />}
                {isAddingUser && <FontAwesomeIcon icon={faCircleNotch} spin={true} />}
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="menu-actions">
                {ptUser.role === UserRole.ROOT && (
                    <Dropdown.Item
                        as={Button}
                        variant="none"
                        eventKey="1"
                        onClick={() => onRoleSelected(UserRole.APP_ADMIN)}
                    >
                        Admin
                    </Dropdown.Item>
                )}

                <Dropdown.Item as={Button} variant="none" eventKey="2" onClick={() => onRoleSelected(UserRole.USER)}>
                    User
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserRolePicker;
