import clsx from "clsx";
import React from "react";

import "./FormError.scss";

export interface ErrorMessage {
    message?: string;
}

type FormErrorProps = {
    error?: ErrorMessage;
    className?: string;
};

const FormError = (props: FormErrorProps) => {
    const { error, className } = props;

    if (!error) {
        return null;
    }

    return <p className={clsx("form-error", className)}>{error.message}</p>;
};

export default FormError;
