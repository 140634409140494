import React from "react";
import { ExploreConstituentModel } from "types/models";
import ConstituentsTableBody from "./ConstituentsTableBody";
import ConstituentsTableHead from "./ConstituentsTableHead";

export interface ConstituentsTableProps {
    constituents: ExploreConstituentModel[];
}

const ConstituentsTable = ({ constituents }: ConstituentsTableProps) => {
    return (
        <div className="constituents-table-container">
            <span className="label">{`Active ingredients (${constituents.length} selected)`}</span>

            <div className="constituents-table">
                <table>
                    <ConstituentsTableHead constituents={constituents} />

                    <ConstituentsTableBody constituents={constituents} />
                </table>
            </div>
        </div>
    );
};

export default ConstituentsTable;
