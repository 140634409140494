import { ChartDataset, ChartOptions } from "chart.js";
import clsx from "clsx";
import PTChart from "Components/Charts/PTChart";
import { isNil, merge } from "lodash";
import React, { useMemo } from "react";
import { ChartScaleOptions, CropConstituentModel, ExploreConstituentModel } from "types/models";
import { roundToString } from "utils/utils";
import GradientBar from "./GradientBar";

import "./PTConstituentsChart.scss";

export interface PTConstituentsChartProps {
    constituents: PTConstituentChartConstituentModel[];
    selectedConstituentId: string;
    chartScale: ChartScaleOptions;
    showLabels: boolean;
    className?: string;
    handleUpdateSelectedConstituentId: (next: string) => void;
}

export interface PTConstituentChartConstituentModel extends CropConstituentModel {
    concentration: number;
    applicationRate: number;
    measureOfEffect: number;
    aquaticRisk: number;
}

interface ChartPointModel {
    x: number;
    y: number;
    constituent: ExploreConstituentModel;
}

const CHART_WIDTH = 600;
const CHART_HEIGHT = 600;

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    layout: {
        padding: 0
    },
    interaction: {
        intersect: true,
        mode: "index"
    },
    onHover: (event, activeElements) => {
        (event?.native?.target as HTMLElement).style.cursor = activeElements?.length > 0 ? "pointer" : "auto";
    },
    plugins: {
        tooltip: {
            enabled: true,
            displayColors: false,
            callbacks: {
                title: function (tooltipItems) {
                    const tooltipItem = tooltipItems[0];
                    const constituent = (tooltipItem.raw as ChartPointModel).constituent;

                    return constituent.name;
                },
                label: function (tooltipItem) {
                    const constituent = (tooltipItem.raw as ChartPointModel).constituent;
                    const aquaticRisk = constituent.aquaticRisk ?? constituent.defaultAquaticRisk;
                    const measureOfEffect = constituent.measureOfEffect ?? constituent.defaultMeasureOfEffect;

                    return [
                        `Aquatic risk score: ${roundToString(aquaticRisk, 0)}`,
                        `Aquatic toxicity: ${roundToString(measureOfEffect, 1)}`,
                        `Persistence and runoff likelihood: ${roundToString(
                            constituent.defaultMeasureOfMobilityAndPersistance,
                            1
                        )}`
                    ];
                }
            }
        },
        legend: {
            display: false
        },
        datalabels: {
            display: false,
            formatter: function (value) {
                return value.constituent.name;
            },
            color: "#ffffff",
            align: "right",
            offset: 6,
            anchor: "end"
        }
    },
    animation: false,
    scales: {
        y: {
            display: false,
            type: "logarithmic",
            min: 0.1,
            max: 10000000
        },
        x: {
            display: false,
            type: "logarithmic",
            min: 0.1,
            max: 100000
        }
    }
};

const WHITE = "#ffffff";
const OFF_WHITE = "#f7f7f8";
const GREY = "#77777a";

const PTConstituentsChart = ({
    constituents,
    selectedConstituentId,
    chartScale,
    showLabels,
    className,
    handleUpdateSelectedConstituentId
}: PTConstituentsChartProps) => {
    const ensureValueInBounds = (value: number, lowerBound: number, upperBound: number): number => {
        if (value < lowerBound) {
            return lowerBound;
        }

        if (value > upperBound) {
            return upperBound;
        }

        return value;
    };

    const data: ChartDataset = useMemo(() => {
        return {
            data: constituents?.map(c => {
                return {
                    x: ensureValueInBounds(c.defaultMeasureOfMobilityAndPersistance, 0.1, 100000),
                    y: ensureValueInBounds(c.measureOfEffect ?? c.defaultMeasureOfEffect, 0.1, 100000000),
                    constituent: c
                };
            }),
            backgroundColor: Array(constituents.length).fill(OFF_WHITE),
            borderColor: WHITE,
            radius: 8,
            hoverRadius: 10,
            borderWidth: -8
        };
    }, [constituents]);

    const styledData: ChartDataset = useMemo(() => {
        if (isNil(data) || isNil(selectedConstituentId)) {
            return data;
        }

        const nextData = {
            ...data,
            backgroundColor: constituents.map(c => {
                return c.id === selectedConstituentId ? GREY : OFF_WHITE;
            })
        };

        return nextData;
    }, [data, selectedConstituentId]);

    const chartOptions: ChartOptions = useMemo(() => {
        const scale = chartScale === ChartScaleOptions.LOG ? "logarithmic" : "linear";

        return merge<ChartOptions, ChartOptions, ChartOptions, ChartOptions, ChartOptions>(
            {},
            CHART_OPTIONS,
            {
                onClick: function (_, clickedElements) {
                    if (clickedElements.length === 0) {
                        return;
                    }

                    const clickedElement = clickedElements[0];

                    const clickedChartPoint = styledData.data[clickedElement.index] as ChartPointModel;

                    handleUpdateSelectedConstituentId(clickedChartPoint.constituent.id);
                }
            },
            {
                scales: {
                    y: {
                        type: scale
                    },
                    x: {
                        type: scale
                    }
                }
            },
            {
                plugins: {
                    datalabels: {
                        display: showLabels
                    }
                }
            }
        );
    }, [CHART_OPTIONS, styledData, chartScale, showLabels]);

    return (
        <div className={clsx("constituents-chart", className)}>
            <span className="label">Risk of active ingredients to the aquatic environment</span>
            <div className="chart-container">
                <div className="chart">
                    <PTChart
                        id={chartScale === ChartScaleOptions.LOG ? "chart-log" : "chart-linear"}
                        type="scatter"
                        data={[styledData]}
                        options={chartOptions}
                        height={CHART_HEIGHT}
                        width={CHART_WIDTH}
                    />
                </div>

                <div className="y-labels" style={{ height: CHART_HEIGHT }}>
                    <span className="axis-label">Highest risk</span>
                    <span className="title">Aquatic toxicity</span>
                    <span className="axis-label">Lowest risk</span>
                </div>

                <div className="x-labels" style={{ width: CHART_WIDTH }}>
                    <span className="axis-label">Lowest risk</span>
                    <span className="title">Persistence and runoff likelihood</span>
                    <span className="axis-label">Highest risk</span>
                </div>

                <GradientBar
                    constituent={constituents.find(c => c.id === selectedConstituentId) ?? null}
                    width={CHART_WIDTH}
                    chartScale={chartScale}
                />
            </div>
        </div>
    );
};

export default PTConstituentsChart;
