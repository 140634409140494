import Assess from "Scenes/Assess";
import Explore from "Scenes/Explore";
import ManageAccount from "Scenes/ManageAccount";
import Register from "Scenes/Register";
import { PTRouteMap } from "./types";

const AppRoutes: PTRouteMap = Object.freeze({
    Explore: {
        route: "/explore",
        scene: Explore,
        exact: true,
        authenticated: true,
        admin: false
    },
    Assess: {
        route: "/assess",
        scene: Assess,
        exact: true,
        authenticated: true,
        admin: false
    },
    ManageAccount: {
        route: "/manage-account",
        scene: ManageAccount,
        exact: true,
        authenticated: true,
        admin: false
    },
    Register: {
        route: "/register",
        scene: Register,
        exact: true,
        authenticated: false,
        admin: false
    }
});

export default AppRoutes;
