import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import Input from "Components/Input";
import LoadingIndicator from "Components/LoadingIndicator";
import { Toast } from "Components/Toast";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ConstituentService from "Services/constituent.service";
import { ExploreConstituentModel, ProductModel } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import ConstituentInfoProductListTable from "./ConstituentInfoProductListTable";
import { useExplore } from "./ExploreContext";

export interface ConstituentInfoProductsList {
    constituent: ExploreConstituentModel;
}

const ConstituentInfoProductList = ({ constituent }: ConstituentInfoProductsList) => {
    const { constituents, selectedConstituentId, updateConstituentConcentration } = useExplore();

    const [search, setSearch] = useState<string>("");
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const getProducts = async () => {
            try {
                setIsLoading(true);

                const nextProducts = await ConstituentService.getConstituentProducts(constituent.id);

                const adjustedProducts = nextProducts.map(p => {
                    return {
                        ...p,
                        constituents: p.constituents.filter(c =>
                            constituents.some(cc => !isNilOrEmpty(cc.pubcrisCode) && c.code.includes(cc.pubcrisCode))
                        )
                    };
                });

                setProducts(adjustedProducts);
            } finally {
                setIsLoading(false);
            }
        };

        getProducts();
    }, [constituent.id]);

    const filteredProducts = useMemo(() => {
        if (isNilOrEmpty(search)) {
            return products;
        }

        const searchMatch = (normalisedName: string, normalisedSearch: string) => {
            return normalisedName.includes(normalisedSearch);
        };

        const normalisedSearch = search.toLowerCase();

        return products.filter(p => searchMatch(p.name.toLowerCase(), normalisedSearch));
    }, [products, search]);

    const handleProductApplyValues = (product: ProductModel) => {
        product.constituents.forEach(pc => {
            const productConstituent = constituents.find(
                c => !isNilOrEmpty(c.pubcrisCode) && pc.code.includes(c.pubcrisCode)
            );

            if (isNil(productConstituent)) {
                return;
            }

            updateConstituentConcentration(productConstituent, pc.concentration);
        });

        Toast.success("Applied concentration(s)");
    };

    const selectedConstituent = constituents.find(c => c.id === selectedConstituentId);

    return (
        <div className="product-list-container">
            {isLoading && <LoadingIndicator centered={true} />}

            {!isLoading && (
                <>
                    <span className="title">{`Products containing ${selectedConstituent.name} (${filteredProducts.length} products)`}</span>

                    <Input
                        icon={faSearch}
                        placeholder="Filter product results from PubCris database"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />

                    <ConstituentInfoProductListTable
                        products={filteredProducts}
                        onProductApplyValues={handleProductApplyValues}
                    />
                </>
            )}
        </div>
    );
};

export default ConstituentInfoProductList;
