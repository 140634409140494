import { isNil } from "lodash";
import React, { useMemo } from "react";
import { ChartScaleOptions } from "types/models";
import { PTConstituentChartConstituentModel } from ".";

export interface GradientBarProps {
    constituent: PTConstituentChartConstituentModel;
    width: number;
    chartScale: ChartScaleOptions;
}

const MIN_VALUE = 0.1;
const MAX_VALUE = 100000000000;

const calculateLogPosition = (value: number): number => {
    if (value === 0) {
        return 0;
    }

    const minLog = Math.log(MIN_VALUE);
    const maxLog = Math.log(MAX_VALUE);
    const range = maxLog - minLog;

    const position = (Math.log(value + 1) - minLog) / range;

    return position <= 1 ? position : 1;
};

const calculateLinearPosition = (value: number): number => {
    const range = MAX_VALUE - MIN_VALUE;

    const position = (value - MIN_VALUE) / range;

    return position <= 1 ? position : 1;
};

const GradientBar = ({ constituent, width, chartScale }: GradientBarProps) => {
    const constituentMarkerPosition = useMemo(() => {
        if (isNil(constituent)) {
            return;
        }

        const position =
            chartScale === ChartScaleOptions.LOG
                ? calculateLogPosition(constituent.aquaticRisk)
                : calculateLinearPosition(constituent.aquaticRisk);

        return width * position;
    }, [constituent?.aquaticRisk, chartScale]);

    return (
        <div className="gradient-bar-container" style={{ width: width }}>
            <span className="title">Aquatic risk score</span>

            {chartScale === ChartScaleOptions.LOG && (
                <>
                    <div className="gradient-bar-log">
                        {!isNil(constituentMarkerPosition) && (
                            <div className="constituent-marker" style={{ left: constituentMarkerPosition }}></div>
                        )}
                    </div>
                    <div>
                        <span style={{ left: 0.085 * width }} className="gradient-bar-label">
                            {"<1"}
                        </span>
                        <span style={{ left: 0.125 * width }} className="gradient-bar-label">
                            {"10"}
                        </span>
                        <span style={{ left: 0.166 * width }} className="gradient-bar-label">
                            {"100"}
                        </span>
                        <span style={{ left: 0.2 * width }} className="gradient-bar-label">
                            {"1,000"}
                        </span>
                        <span style={{ left: 0.275 * width }} className="gradient-bar-label">
                            {"100,000"}
                        </span>
                        <span style={{ left: 0.325 * width }} className="gradient-bar-label">
                            {"10,000,000"}
                        </span>
                        <span style={{ left: 0.42 * width }} className="gradient-bar-label">
                            {"100,000,000,000"}
                        </span>
                    </div>
                </>
            )}

            {chartScale === ChartScaleOptions.LINEAR && (
                <>
                    <div className="gradient-bar-linear">
                        {!isNil(constituentMarkerPosition) && (
                            <div className="constituent-marker" style={{ left: constituentMarkerPosition }}></div>
                        )}
                    </div>
                    <div>
                        <span style={{ left: 0 }} className="gradient-bar-label">
                            {"0"}
                        </span>
                        <span style={{ left: 0.83 * width }} className="gradient-bar-label">
                            {"100,000,000,000"}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default GradientBar;
