import PTConstituentsChart from "Components/PTConstituentsChart";
import React from "react";
import { ChartScaleOptions } from "types/models";
import { useMixView } from "./MixViewContext";
import MixViewResult from "./MixViewResult";
import MixViewTable from "./MixViewTable";

const MixViewBody = () => {
    const { constituents, selectedConstituentId, updateSelectedConstituentId } = useMixView();

    return (
        <>
            <MixViewTable />

            <div className="results-container">
                <MixViewResult />

                <div className="constituents-chart-container">
                    <PTConstituentsChart
                        constituents={constituents.filter(c => c.isEnabled)}
                        selectedConstituentId={selectedConstituentId}
                        chartScale={ChartScaleOptions.LOG}
                        showLabels={false}
                        className={"constituents-chart-padding"}
                        handleUpdateSelectedConstituentId={updateSelectedConstituentId}
                    />
                </div>
            </div>
        </>
    );
};

export default MixViewBody;
