import PTSelect from "Components/PTSelect";
import { SelectOption } from "Components/Select/Select";
import React, { useMemo } from "react";
import { PesticideType } from "types/models";
import { useExplore } from "./ExploreContext";

export interface FilterOptions {
    cropCode: string;
    pesticideType: PesticideType;
}

const Filter = () => {
    const { crops, filterOptions, updateFilterOptions } = useExplore();

    const cropSelectValues: SelectOption[] = useMemo(() => {
        return crops.map(c => {
            return {
                label: c.name,
                value: c.code
            };
        });
    }, [crops]);

    const pestSelectValues: SelectOption[] = [
        { label: "All Pests", value: PesticideType.ALL },
        { label: "Fungi", value: PesticideType.FUNGICIDE },
        { label: "Insects", value: PesticideType.INSECTICIDE },
        { label: "Nematodes", value: PesticideType.NEMATICIDE },
        { label: "Weeds", value: PesticideType.HERBICIDE }
    ];

    const handleCropSelected = (value: SelectOption) => {
        updateFilterOptions({ ...filterOptions, cropCode: value.value });
    };

    const handlePestSelected = (value: SelectOption) => {
        updateFilterOptions({
            ...filterOptions,
            pesticideType: value.value as PesticideType
        });
    };

    return (
        <div className="filter">
            <span className="filter-text">I am growing</span>

            <PTSelect
                values={cropSelectValues}
                value={cropSelectValues.find(c => c.value === filterOptions.cropCode) ?? cropSelectValues[0]}
                canSearch={true}
                searchPlaceholder={"Search crop"}
                label="Change crop"
                onSelect={handleCropSelected}
            />

            <span className="filter-text">and want to control</span>

            <PTSelect
                values={pestSelectValues}
                value={pestSelectValues.find(p => p.value === filterOptions.pesticideType) ?? pestSelectValues[0]}
                canSearch={false}
                label="Change pest"
                onSelect={handlePestSelected}
            />
        </div>
    );
};

export default Filter;
