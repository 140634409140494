import { faCheck, faExclamationCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { merge } from "lodash";
import React from "react";
import { Slide, toast, ToastOptions, ToastPosition } from "react-toastify";

const DEFAULT_TOAST_OPTIONS: ToastOptions = {
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    transition: Slide,
    closeButton: false,
    style: {
        borderRadius: 10,
        fontSize: 14,
        backgroundColor: "#252628",
        border: "1px solid #707070",
        color: "#fff"
    }
};

export class Toast {
    static success(text: string | React.ReactNode, position: ToastPosition = "bottom-center", closeButton = false) {
        toast.success(text, {
            ...DEFAULT_TOAST_OPTIONS,
            position: position,
            closeButton: closeButton,
            icon: () => <FontAwesomeIcon icon={faCheck} size="lg" color="#43ad49" />
        });
    }

    static error(text: string, position: ToastPosition = "bottom-center") {
        toast.error(text, {
            ...DEFAULT_TOAST_OPTIONS,
            position: position,
            icon: () => <FontAwesomeIcon icon={faTimesCircle} size="lg" color="#d2272e" />
        });
    }

    static warn(text: string, position: ToastPosition = "bottom-center") {
        toast.error(text, {
            ...DEFAULT_TOAST_OPTIONS,
            position: position,
            icon: () => <FontAwesomeIcon icon={faExclamationCircle} size="lg" color="#f8952d" />
        });
    }

    static custom(text: React.ReactNode, options: ToastOptions) {
        toast.success(text, merge({}, DEFAULT_TOAST_OPTIONS, options));
    }
}
