import NavigationTabs, { NavigationTabType } from "Components/NavigationTabs";
import PageTitle from "Components/PageTitle";
import React, { useState } from "react";
import AssessContextProvider from "./AssessContext";
import AssessBody from "./AssessBody";

import "./Assess.scss";

const Assess = () => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    const selectedMixId = localStorage.getItem("selectedMixId") ?? null;

    return (
        <AssessContextProvider updateHasUnsavedChanges={setHasUnsavedChanges}>
            <div className="assess-container">
                <PageTitle title="Assess" />

                <NavigationTabs selectedTab={NavigationTabType.ASSESS} hasUnsavedChages={hasUnsavedChanges} />

                <AssessBody selectedMixId={selectedMixId} />
            </div>
        </AssessContextProvider>
    );
};

export default Assess;
