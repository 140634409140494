import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import ERPIcon from "Components/ERPIcon";
import NumericInput from "Components/NumericInput";
import ERPSelect, { SelectOption } from "Components/Select/Select";
import Switch from "Components/Switch";
import { round } from "lodash";
import React from "react";
import { AssessConstituentModel, SprayMethod } from "types/models";
import { roundToString } from "utils/utils";
import { useMixView } from "./MixViewContext";

export interface MixViewTableBodyRowProps {
    constituent: AssessConstituentModel;
}

const SPRAY_SELECT_OPTIONS: SelectOption[] = [
    { label: "Complete coverage", value: SprayMethod.COMPLETE },
    { label: "Majority (75% coverage)", value: SprayMethod.MAJORITY },
    { label: "Banded (60% coverage)", value: SprayMethod.BANDED },
    { label: "Part (25% coverage)", value: SprayMethod.PART },
    { label: "Spot (10% coverage)", value: SprayMethod.SPOT }
];

const MixViewTableBodyRow = ({ constituent }: MixViewTableBodyRowProps) => {
    const {
        selectedConstituentId,
        mixRiskScore,
        updateSelectedConstituentId,
        updateConstituentConcentration,
        updateConstituentApplicationRate,
        updateConstituentSprayMethod,
        updateConstituentIsEnabled,
        handleRemoveConstituent
    } = useMixView();

    const handleConstituentSelected = () => {
        if (selectedConstituentId === constituent.id) {
            updateSelectedConstituentId(null);
            return;
        }

        updateSelectedConstituentId(constituent.id);
    };

    const handleConcentrationChanged = (nextConcentration: number) => {
        updateConstituentConcentration(constituent, nextConcentration);
    };

    const handleApplicationRateChanged = (nextApplicationRate: number) => {
        updateConstituentApplicationRate(constituent, nextApplicationRate);
    };

    const handleIsEnabledChanged = (isChecked: boolean) => {
        updateConstituentIsEnabled(constituent, isChecked);
    };

    const handleSprayMethodChanged = (nextSprayMethod: SprayMethod) => {
        updateConstituentSprayMethod(constituent, nextSprayMethod);
    };

    const handleConstituentRemoved = () => {
        handleRemoveConstituent(constituent);
    };

    const isSelected = selectedConstituentId === constituent.id;
    const selectedSprayOption = SPRAY_SELECT_OPTIONS.find(o => o.value === constituent.sprayMethod);

    return (
        <tr className={clsx({ selected: isSelected, disabled: !constituent.isEnabled && !isSelected })}>
            <td className="centered-cell">
                <Switch checked={constituent.isEnabled} onChecked={handleIsEnabledChanged} />
            </td>
            <td className="selectable-cell" onClick={() => handleConstituentSelected()}>
                {constituent.name}
            </td>
            <td className="selectable-cell right-align-cell" onClick={() => handleConstituentSelected()}>
                {round(constituent.defaultApplicationRate, 2)}
            </td>
            <td className="selectable-cell right-align-cell bold-cell" onClick={() => handleConstituentSelected()}>
                {roundToString(constituent.defaultAquaticRisk, 0)}
            </td>

            {isSelected && (
                <td className="blue-cell right-align-cell">
                    <NumericInput
                        value={constituent.concentration}
                        min={0}
                        max={1000}
                        allowEmpty={false}
                        onChange={handleConcentrationChanged}
                    />
                </td>
            )}
            {!isSelected && (
                <td className="selectable-cell blue-cell right-align-cell" onClick={() => handleConstituentSelected()}>
                    {constituent.concentration}
                </td>
            )}

            {isSelected && (
                <td className="blue-cell right-align-cell">
                    <NumericInput
                        value={constituent.applicationRate}
                        min={0}
                        allowEmpty={false}
                        onChange={handleApplicationRateChanged}
                    />
                </td>
            )}
            {!isSelected && (
                <td className="selectable-cell blue-cell right-align-cell" onClick={() => handleConstituentSelected()}>
                    {constituent.applicationRate}
                </td>
            )}

            <td
                className="selectable-cell blue-cell right-align-cell bold-cell"
                onClick={() => handleConstituentSelected()}
            >
                {roundToString(constituent.aquaticRisk, 0)}
            </td>

            {isSelected && (
                <td className="green-cell right-align-cell select-dropdown">
                    <ERPSelect
                        value={selectedSprayOption}
                        values={SPRAY_SELECT_OPTIONS}
                        onSelected={(option: SelectOption) => {
                            handleSprayMethodChanged(option.value as SprayMethod);
                        }}
                        isClearable={false}
                        isSearchable={false}
                    />
                </td>
            )}
            {!isSelected && (
                <td className="selectable-cell green-cell centered-cell" onClick={() => handleConstituentSelected()}>
                    {selectedSprayOption.label}
                </td>
            )}
            <td className="selectable-cell centered-cell bold-cell" onClick={() => handleConstituentSelected()}>
                {round(constituent.appliedApplicationRate, 2)}
            </td>
            <td className="selectable-cell centered-cell bold-cell" onClick={() => handleConstituentSelected()}>
                {roundToString(constituent.appliedRiskUnits, 0)}
            </td>
            <td className="selectable-cell centered-cell bold-cell" onClick={() => handleConstituentSelected()}>
                {constituent.isEnabled && constituent.appliedRiskUnits > 0
                    ? round((constituent.appliedRiskUnits / mixRiskScore) * 100, 2)
                    : "-"}
            </td>
            <td className="centered-cell">
                <ERPIcon
                    icon={faMinusCircle}
                    className="remove-icon"
                    size="lg"
                    onClick={() => handleConstituentRemoved()}
                />
            </td>
        </tr>
    );
};

export default MixViewTableBodyRow;
