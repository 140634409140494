import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "Components/ERPIcon";
import React, { useState } from "react";

export interface MixSelectAddToggleProps {
    onClick: (e: any) => void;
}

const MixSelectAddToggle = React.forwardRef(({ onClick }: MixSelectAddToggleProps, ref: any) => {
    const [allowClick, setAllowClick] = useState<boolean>(false);

    return (
        <div
            className="mix-select-toggle-add"
            onClick={e => {
                if (allowClick) {
                    e.preventDefault();
                    onClick(e);
                }
            }}
            ref={ref}
        >
            <ERPIcon
                icon={faPlusCircle}
                size="lg"
                className="add-icon"
                onMouseEnter={() => setAllowClick(true)}
                onMouseLeave={() => setAllowClick(false)}
            />
        </div>
    );
});

export default MixSelectAddToggle;
