import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import Config from "Config";
import { PTEnvironment } from "Config/types";

class ERPTelemetry {
    private isStarted: boolean;
    private client: ApplicationInsights = null;

    public start(): void {
        if (Config.environment === PTEnvironment.DEVELOPMENT) {
            return;
        }

        this.client = new ApplicationInsights({
            config: {
                connectionString: Config.ai.connectionString,
                distributedTracingMode: DistributedTracingModes.AI,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                disableCorrelationHeaders: false,
                enableResponseHeaderTracking: true,
                enableAutoRouteTracking: true,
                correlationHeaderExcludedDomains: [Config.auth0.audience]
            }
        });

        this.client.loadAppInsights();

        this.isStarted = true;
    }

    public setUser(userId: string): void {
        this.client?.setAuthenticatedUserContext(userId, null, true);
    }

    public clearUser(): void {
        this.client?.clearAuthenticatedUserContext();
    }

    public trackEvent(name: string, properties?: Record<any, any>): void {
        if (!this.isStarted) {
            return;
        }

        return this.client.trackEvent({
            name: name,
            properties: properties
        });
    }

    public trackException(error: Error, properties?: Record<any, any>): void {
        if (!this.isStarted) {
            return;
        }

        return this.client.trackException({
            exception: error,
            properties: properties
        });
    }
}

export default new ERPTelemetry();
