import Label from "Components/Label";
import React from "react";
import { ExploreConstituentModel } from "types/models";
import ConstituentInfoActiveIngredient from "./ConstituentInfoActiveIngredient";
import ConstituentInfoBox from "./ConstituentInfoBox";

export interface ConstituentInfoProps {
    constituent: ExploreConstituentModel;
}

const ConstituentInfo = ({ constituent }: ConstituentInfoProps) => {
    const measureOfEffect = constituent.measureOfEffect ?? constituent.defaultMeasureOfEffect;
    const aquaticRisk = constituent.aquaticRisk ?? constituent.defaultAquaticRisk;

    return (
        <div className="constituent-info">
            <Label className="constituent-name-label">{constituent.name}</Label>

            <ConstituentInfoBox
                aquaticRisk={aquaticRisk}
                measureOfEffect={measureOfEffect}
                measureOfMobilityAndPersistence={constituent.defaultMeasureOfMobilityAndPersistance}
            />

            <ConstituentInfoActiveIngredient
                pesticideType={constituent.pesticideType}
                casNo={constituent.casNo}
                chemicalGroup={constituent.chemicalGroup}
            />
        </div>
    );
};

export default ConstituentInfo;
