import CalculationsService from "Services/calculations.service";
import { AssessConstituentModel, MixConstituentModel, SprayMethod } from "types/models";

export const getCalculatedConstituent = (
    constituent: AssessConstituentModel,
    nextApplicationRate: number,
    nextConcentration: number,
    nextSprayMethod: SprayMethod
): AssessConstituentModel => {
    const measureOfEffect = CalculationsService.calculateMeasureOfEffect(
        nextApplicationRate,
        nextConcentration,
        constituent.ecotoxicityThreshold,
        constituent.weight
    );
    const appliedApplicationRate =
        (nextConcentration / 1000) * nextApplicationRate * mapSprayMethodToDecimalValue(nextSprayMethod);
    const riskUnits = constituent.defaultMeasureOfMobilityAndPersistance / constituent.ecotoxicityThreshold;
    const appliedRiskUnits = appliedApplicationRate * riskUnits;

    return {
        ...constituent,
        concentration: nextConcentration,
        applicationRate: nextApplicationRate,
        sprayMethod: nextSprayMethod,
        measureOfEffect: measureOfEffect,
        aquaticRisk: CalculationsService.calculateAquaticRisk(
            measureOfEffect,
            constituent.defaultMeasureOfMobilityAndPersistance
        ),
        appliedApplicationRate: appliedApplicationRate,
        appliedRiskUnits: appliedRiskUnits
    };
};

const mapSprayMethodToDecimalValue = (sprayMethod: SprayMethod): number => {
    switch (sprayMethod) {
        case SprayMethod.COMPLETE:
            return 1;

        case SprayMethod.MAJORITY:
            return 0.75;

        case SprayMethod.BANDED:
            return 0.6;

        case SprayMethod.PART:
            return 0.25;

        case SprayMethod.SPOT:
            return 0.1;

        default:
            return 1;
    }
};

export const convertToAssessConstituent = (constituent: MixConstituentModel): AssessConstituentModel => {
    const measureOfEffect = CalculationsService.calculateMeasureOfEffect(
        constituent.applicationRate,
        constituent.concentration,
        constituent.ecotoxicityThreshold,
        constituent.weight
    );
    const appliedApplicationRate =
        (constituent.concentration / 1000) *
        constituent.applicationRate *
        mapSprayMethodToDecimalValue(constituent.sprayMethod);
    const riskUnits = constituent.defaultMeasureOfMobilityAndPersistance / constituent.ecotoxicityThreshold;
    const appliedRiskUnits = appliedApplicationRate * riskUnits;

    return {
        ...constituent,
        measureOfEffect: measureOfEffect,
        aquaticRisk: CalculationsService.calculateAquaticRisk(
            measureOfEffect,
            constituent.defaultMeasureOfMobilityAndPersistance
        ),
        appliedApplicationRate: appliedApplicationRate,
        appliedRiskUnits: appliedRiskUnits,
        isEnabled: constituent.isEnabled
    };
};
