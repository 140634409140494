import React from "react";
import Input, { InputProps } from "Components/Input";
import FormError, { ErrorMessage } from "Components/FormError/FormError";
import Label from "Components/Label";
import clsx from "clsx";

export type FormInputProps = {
    labelText?: string;
    labelClass?: string;
    error?: ErrorMessage;
    errorClassName?: string;
    containerClassName?: string;
} & InputProps;

const FormInput = (props: FormInputProps) => {
    const { labelText, labelClass, error, errorClassName, name, containerClassName, ...rest } = props;

    return (
        <div className={clsx("form-group", containerClassName)}>
            {labelText && (
                <Label className={labelClass} htmlFor={name}>
                    {labelText}
                </Label>
            )}

            <Input name={name} {...rest}></Input>

            <FormError className={errorClassName} error={error} />
        </div>
    );
};

export default FormInput;
