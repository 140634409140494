export default class CalculationsService {
    static calculateMeasureOfEffect(
        applicationRate: number,
        concentration: number,
        ecotoxicityThreshold: number,
        weight: number
    ): number {
        const concentraionProportion = concentration / 1000;

        const calculatedEcotoxicity = (ecotoxicityThreshold / weight) * 1000000;
        const calculatedApplicationRate = (applicationRate * concentraionProportion * 1000000000) / weight;

        return calculatedApplicationRate / calculatedEcotoxicity;
    }

    static calculateAquaticRisk(measureOfEffect: number, measureOfMobilityAndPersistance: number): number {
        return measureOfMobilityAndPersistance * (measureOfEffect / 100);
    }
}
