import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BreakpointProvider } from "Hooks/useBreakpoints";
import { ToastContainer } from "react-toastify";
import { ConfirmationProvider } from "Components/ConfirmDialog/ConfirmationContext";
import { AppState, Auth0Provider } from "auth/react-auth0-spa";
import { createBrowserHistory } from "history";
import Config from "Config";
import { isNil } from "lodash";
import ERPTelemetry from "./telemetry/telemetry";
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";

import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-contexify/dist/ReactContexify.css";
import "./index.scss";

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.register(ChartDataLabels);

ERPTelemetry.start();

const onRedirectCallback = (appState?: AppState): void => {
    if (!isNil(appState) && !isNil(appState.backTo)) {
        const history = createBrowserHistory();

        history?.push(appState.backTo);

        window.location.reload();
    }
};

ReactDOM.render(
    <React.StrictMode>
        <BreakpointProvider>
            <Auth0Provider
                domain={Config.auth0.audience}
                client_id={Config.auth0.clientId}
                redirect_uri={Config.auth0.redirectUri}
                useRefreshTokens
                cacheLocation="localstorage"
                onRedirectCallback={onRedirectCallback}
            >
                <ConfirmationProvider>
                    <>
                        <App />

                        <ToastContainer className="epr-toast-container" />
                    </>
                </ConfirmationProvider>
            </Auth0Provider>
        </BreakpointProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
