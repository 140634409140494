import React from "react";
import { Dropdown } from "react-bootstrap";
import MixSelectMenu from "./MixSelectMenu";
import MixSelectDropdownToggle from "./MixSelectDropdownToggle";
import { MixModel } from "types/models";

import "./MixSelect.scss";
import MixSelectAddToggle from "./MixSelectAddToggle";
import MixSelectCompareToggle from "./MixSelectCompareToggle";

export enum MixSelectStyle {
    DROPDOWN = "dropdown",
    ADD = "add",
    COMPARE = "compare"
}

export interface MixSelectProps {
    mixes: MixModel[];
    selectedMixId?: string;
    style: MixSelectStyle;
    disabled?: boolean;
    onMixSelected: (nextMixId: string) => void;
    updateMixes: (nextMixes: MixModel[]) => void;
}

const MixSelect = ({ mixes, selectedMixId, style, disabled, onMixSelected, updateMixes }: MixSelectProps) => {
    const selectedMix = mixes.find(m => m.id === selectedMixId);

    return (
        <Dropdown className="mix-select-container">
            {style === MixSelectStyle.DROPDOWN && (
                <Dropdown.Toggle as={MixSelectDropdownToggle} selectedMix={selectedMix} />
            )}

            {style === MixSelectStyle.ADD && <Dropdown.Toggle as={MixSelectAddToggle} />}

            {style === MixSelectStyle.COMPARE && <Dropdown.Toggle as={MixSelectCompareToggle} disabled={disabled} />}

            <Dropdown.Menu
                as={MixSelectMenu}
                mixes={mixes}
                selectedMix={selectedMix}
                onMixSelected={onMixSelected}
                updateMixes={updateMixes}
            />

            {style === MixSelectStyle.DROPDOWN && <span className="mix-select-label">Change mix</span>}
        </Dropdown>
    );
};

export default MixSelect;
