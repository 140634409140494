import React, { useMemo } from "react";
import { faBug, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { useLocation } from "react-router-dom";
import LabelHeaderContent from "./LabelHeaderContent";
import HeaderIcon from "./HeaderIcon";
import UserMenu from "./UserMenu";
import { startsWith } from "lodash";
import { useAuth0 } from "auth/react-auth0-spa";
import Button from "Components/Button";

import "./Header.scss";

const Header = () => {
    const { isAuthenticated, ptUser, logout } = useAuth0();

    const location = useLocation();

    const HeaderContent = useMemo(() => {
        const pathname = location.pathname;

        //Custom Headers
        if (startsWith(pathname, "/manage-account")) {
            return <LabelHeaderContent label="Manage Account" icon={faUsers} iconClassName="manage-account-icon" />;
        }

        //No Header
        return null;
    }, [location.pathname]);

    const handleReportIssue = () => {
        const logURL =
            "https://truii0.sharepoint.com/:x:/s/Projects/ETgUQYj0h-1Pi7kI5FD4yOoBydLbd0OpnxKzRr8PG8HKdg?e=MNfFY6";
        window.open(logURL, "_blank");
    };

    if (isAuthenticated) {
        return (
            <div className="pt-header">
                <div className="header-icon-container">
                    <HeaderIcon />
                    <div className="beta-logo">Beta</div>
                    {HeaderContent}
                </div>

                <div className="header-menu-container">
                    <Button variant="blue" icon={faBug} onClick={() => handleReportIssue()} className="issue-button">
                        Report an issue
                    </Button>

                    <UserMenu ptUser={ptUser} logout={logout} />
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Header;
