import Config from "Config";
import { API } from "./base.api";

class DataAPI extends API {
    constructor() {
        super(Config.apiUrl);
    }
}

export default new DataAPI();
