import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import ERPIcon from "Components/ERPIcon";
import React, { useState } from "react";
import { MixModel } from "types/models";

export interface MixSelectItemProps {
    mix: MixModel;
    isSelected: boolean;
    onMixSelected: (next: MixModel) => void;
    onMixEdit: (mix: MixModel) => void;
    onMixDelete: (mix: MixModel) => void;
    onClick?: (e: any) => void;
}

const MixSelectItem = React.forwardRef(
    ({ mix, isSelected, onMixSelected, onMixEdit, onMixDelete, onClick }: MixSelectItemProps, ref: any) => {
        const [shouldRegisterClick, setShouldRegisterClick] = useState<boolean>(true);

        const handleEditMix = () => {
            onMixEdit(mix);
        };

        const handleDeleteMix = () => {
            onMixDelete(mix);
        };

        return (
            <div
                className={clsx("mix-select-item", { "mix-selected-item": isSelected })}
                ref={ref}
                onClick={e => {
                    if (shouldRegisterClick) {
                        e.preventDefault();
                        onMixSelected(mix);
                        onClick(e);
                    }
                }}
            >
                {mix.name}
                <div className="item-actions">
                    <ERPIcon
                        icon={faPencil}
                        className="action-icon"
                        onClick={() => handleEditMix()}
                        onMouseEnter={() => setShouldRegisterClick(false)}
                        onMouseLeave={() => setShouldRegisterClick(true)}
                    />
                    <ERPIcon
                        icon={faTrash}
                        className="action-icon"
                        onClick={() => handleDeleteMix()}
                        onMouseEnter={() => setShouldRegisterClick(false)}
                        onMouseLeave={() => setShouldRegisterClick(true)}
                    />
                </div>
            </div>
        );
    }
);

export default MixSelectItem;
