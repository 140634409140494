import Button from "Components/Button";
import LoadingButton from "Components/LoadingButton";
import MixModal, { MixContextType, MixModalContext } from "Components/MixSelect/MixModal";
import { isNil } from "lodash";
import React, { useState } from "react";
import { MixModel } from "types/models";
import { useAssess } from "./AssessContext";
import { useMixView } from "./MixViewContext";

const MixViewActions = () => {
    const { mixes, updateMixes } = useAssess();
    const { isSaving, constituents, handleSaveMix, updateMixId } = useMixView();

    const [mixModalContext, setMixModalContext] = useState<MixModalContext>(null);

    const handleSaveAsNewMix = () => {
        setMixModalContext({ type: MixContextType.SAVE_AS, mixConstituents: constituents });
    };

    const onMixCreated = (mix: MixModel) => {
        const nextMixes = [...mixes, mix];
        updateMixes(nextMixes);
        updateMixId(mix.id, false);
    };

    return (
        <div className="actions-container">
            <LoadingButton
                onClick={() => handleSaveMix()}
                disabled={isSaving}
                isLoading={isSaving}
                className="save-button"
            >
                Save mix
            </LoadingButton>

            <Button className="save-as-button" disabled={isSaving} onClick={() => handleSaveAsNewMix()}>
                Save as new mix
            </Button>

            {!isNil(mixModalContext) && (
                <MixModal
                    context={mixModalContext}
                    show
                    onClose={() => setMixModalContext(null)}
                    onMixCreated={onMixCreated}
                />
            )}
        </div>
    );
};

export default MixViewActions;
