import { KNOWN_HTTP_STATUS_CODES } from "api/base.api";
import { User, UserRole } from "types/models";
import { CreateUserRequest, ChangePasswordRequest, RegisterRequest, RegisterResult } from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class UserService {
    public static async me(): Promise<User> {
        const me = await API.get<User>(URL.ME);
        return me;
    }

    static async register(request: RegisterRequest): Promise<RegisterResult> {
        try {
            const user = await API.post<User>(URL.REGISTER, request);

            return { success: true, user: user, error: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data;

                    return {
                        success: false,
                        error: apiError
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async changePassword(request: ChangePasswordRequest): Promise<void> {
        await API.patch<void>(`${URL.ME}/change-password`, request);
    }

    public static async getUsers(): Promise<User[]> {
        const users = await API.get<User[]>(`${URL.USERS}`);

        return users;
    }

    public static async deleteUser(userId: string): Promise<void> {
        await API.delete<void>(`${URL.USERS}/${userId}`);
    }

    public static async createUser(email: string, role: UserRole): Promise<User> {
        const request: CreateUserRequest = {
            email: email,
            role: role
        };

        const user = await API.post<User>(`${URL.USERS}`, request);

        return user;
    }
}
