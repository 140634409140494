import { ProductModel } from "types/models";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class ConstituentService {
    public static async getConstituentProducts(id: string): Promise<ProductModel[]> {
        const products = await API.get<ProductModel[]>(`${URL.CONSTITUENTS}/${id}/products`);

        return products;
    }
}
