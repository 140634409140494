import axios, { AxiosError, AxiosInstance } from "axios";
import { Toast } from "Components/Toast";
import { isNil } from "lodash";

export class API {
    private readonly axios: AxiosInstance;
    private _token: string;
    private unauthorizedInterceptorId: number = null;

    constructor(url: string) {
        this.axios = axios.create({ baseURL: url });

        this.axios.interceptors.response.use(null, error => {
            if (this.isAxiosError(error)) {
                if (isNil(error?.response) || error.response.status >= 500) {
                    Toast.error("Sorry an unexpected error ocurred.");
                } else if (error.response.status === KNOWN_HTTP_STATUS_CODES.NOT_FOUND) {
                    Toast.error("The resources you requested could not be found.");
                }
            }

            throw error;
        });
    }

    public setupUnauthorizedInterceptor(onError: () => void) {
        this.unauthorizedInterceptorId = this.axios.interceptors.response.use(null, error => {
            if (this.isAxiosError(error)) {
                if (!isNil(error.response) && error.response.status === KNOWN_HTTP_STATUS_CODES.UNAUTHORIZED) {
                    onError();
                }
            }

            throw error;
        });
    }

    public removeUnauthorizedInterceptor() {
        if (!isNil(this.unauthorizedInterceptorId)) {
            this.axios.interceptors.response.eject(this.unauthorizedInterceptorId);
        }
    }

    public setToken(token: string): void {
        this._token = token;
    }

    private transformHeaders(headers: Record<string, string> = {}) {
        const newHeaders = {
            ...headers
        };

        if (this._token) {
            newHeaders.Authorization = `Bearer ${this._token}`;
        }

        return newHeaders;
    }

    public async query<T>(url: string, filter: any, headers: Record<string, string> = {}): Promise<T> {
        const serializedFilter = JSON.stringify(filter);

        const response = await this.axios.get(`${url}?filter=${serializedFilter}`, {
            headers: this.transformHeaders(headers)
        });

        return response.data;
    }

    public async get<T>(url: string, headers: Record<string, string> = {}, params?: URLSearchParams): Promise<T> {
        const response = await this.axios.get(url, {
            headers: this.transformHeaders(headers),
            params
        });

        return response.data;
    }

    public async post<T>(url: string, data?: any, headers: Record<string, string> = {}): Promise<T> {
        const response = await this.axios.post(url, data, {
            headers: this.transformHeaders(headers)
        });

        return response.data;
    }

    public async uploadFormData<T>(
        url: string,
        data: FormData,
        headers: Record<string, string> = {},
        onProgress: (progress: number) => void
    ): Promise<T> {
        const response = await this.axios.post(url, data, {
            headers: this.transformHeaders(headers),
            onUploadProgress: progressEvent => {
                if (!isNil(onProgress)) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    onProgress(percentCompleted);
                }
            }
        });

        return response.data;
    }

    public async patch<T>(url: string, data?: any, headers: Record<string, string> = {}): Promise<T> {
        const response = await this.axios.patch(url, data, {
            headers: this.transformHeaders(headers)
        });

        return response.data;
    }

    public async put<T>(url: string, data?: any, headers: Record<string, string> = {}): Promise<T> {
        const response = await this.axios.put(url, data, {
            headers: this.transformHeaders(headers)
        });

        return response.data;
    }

    public async delete<T>(url: string, data?: any, headers: Record<string, string> = {}): Promise<T> {
        const response = await this.axios.request({
            url: url,
            data: data,
            headers: this.transformHeaders(headers),
            method: "DELETE"
        });

        return response.data;
    }

    public isAxiosError(error: Error): error is AxiosError {
        return axios.isAxiosError(error);
    }
}

export const KNOWN_HTTP_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    EXPECTATION_FAILED: 417,
    INTERNAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504
};

export interface ApiError {
    readonly code: string;
    readonly message: string;
    readonly name: string;
    readonly statusCode: number;
}
